<template>
  <div class="wb-100 hb-100 table" :custom="custom">
    <div class="wb-100 h-40 align-center th">
      <!-- 表头 -->
      <div
        class="center hb-100 t-w fz-22 th"
        v-for="(item, index) in column"
        :key="index"
        :class="`wb-${item.width}`">
        {{ item.label }}
      </div>
    </div>
    <div class="wb-100 table_cotain auto" :class="tableCotainClass">
      <template v-if="dataList && dataList.length">
        <!-- <TransitionGroup :name="transName" class="relative wb-100 hb-100 fz-16"> -->
        <div class="relative wb-100 hb-100 fz-16">
          <div
            class="tr wb-100 fz-16"
            v-for="(item, index) in dataList"
            :key="item.rank"
            style="height: 6%">
            <!-- 12.5% -->
            <div class="center wb-100 hb-100">
              <div
                class="center td hb-100 t-w fz-18"
                :class="`wb-${column.width}`"
                v-for="(column, j) in column"
                :key="j + index">
                <div
                  :class="tableTbClass"
                  v-if="
                    column.prop == 'rank' &&
                    [1, 2, 3].includes(item[column.prop])
                  ">
                  <div class="center w-20 h-27">
                    <img :src="rankIconList[item[column.prop]]" alt="" />
                  </div>
                </div>
                <div v-else-if="column.prop == 'gender'" :class="tableTbClass">
                  {{ ["女", "男"][item[column.prop]] }}
                </div>
                <div v-else :class="tableTbClass">
                  {{ item[column.prop] }}
                </div>
                <div v-if="column.prop == 'simple_name'" :class="tableTbClass">
                  {{ item[column.prop] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </TransitionGroup> -->
      </template>

      <div class="t-w fz-20 wb-100 hb-100 center column" v-else>
        <div class="no-image w-195 h-200 center">
          <img src="../assets/images/jump-no-data.png" alt="" />
        </div>
        <div class="mt-21 fz-22 no-text">该比赛未开启，暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    column: {
      type: Array,
    },
    dataList: {
      type: Array,
    },
    transName: {
      type: String,
      default: "fade",
    },
    custom: {
      type: String,
      default: "",
    },
    tdWidth: {
      type: String,
      default: "20",
    },
    tableCotainClass: {
      type: String,
      default: "",
    },
    tableTbClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rankIconList: {
        1: require("../assets/images/jump-one.png"),
        2: require("../assets/images/jump-two.png"),
        3: require("../assets/images/jump-three.png"),
      },
    };
  },
};
</script>
<style scoped>
/* 1. 声明过渡效果 */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. 声明进入和离开的状态 */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(0.3rem, 0);
}

/* 3. 确保离开的项目被移除出了布局流
      以便正确地计算移动时的动画效果。 */
.fade-leave-active {
  position: absolute;
}

.th {
  background-color: #111e4e;
}
.tr {
  /* display: inline-block; */
  background-color: #344a86;
}
.tr:nth-child(2n) {
  background-color: #111e4e;
}
.tr:hover > .td {
  background-color: #111e4e;
}

img {
  width: 100%;
  height: 100%;
}

.table_cotain {
  height: calc(100% - 0.1rem);
}

.no-text {
  color: #1d78fd;
}

.table[custom="long"] {
  .th {
    background-color: #344a86;
  }

  .tr {
    /* display: inline-block; */
    background-color: #111e4e;
  }
  .tr:nth-child(2n) {
    background-color: #344a86;
  }
  .tr:hover > .td {
    background-color: #344a86;
  }
}
</style>
