// 运动指标 枚举
export const Sport_Indicators = [
    {
        title: '参与人数',
        value: 0,
        bottomImg: require('../assets/images/v1.0.0-indication-01.png'),
        unit: '',
        id: 1,
        key: 'participants',
        numType: 'num'
    },
    {
        title: '平均心率',
        value: 0,
        bottomImg: require('../assets/images/v1.0.0-indication-02.png'),
        unit: '次/分',
        id: 2,
        key: 'averageRate',
        numType: 'num'
    },
    {
        title: '最高心率',
        value: 0,
        bottomImg: require('../assets/images/v1.0.0-indication-03.png'),
        unit: '次/分',
        id: 3,
        key: 'averageMaxRate',
        numType: 'num'
    },
    {
        title: '平均运动密度',
        value: 0,
        bottomImg: require('../assets/images/v1.0.0-indication-04.png'),
        unit: '%',
        id: 4,
        key: 'averageDensity',
        numType: 'num'
    },
    {
        title: '平均运动强度',
        value: 0,
        bottomImg: require('../assets/images/v1.0.0-indication-05.png'),
        unit: '%',
        id: 5,
        key: 'averageIntensity',
        numType: 'num'
    },
    {
        title: '靶心率运动时间',
        value: 0,
        bottomImg: require('../assets/images/v1.0.0-indication-06.png'),
        unit: '',
        id: 6,
        key: 'movementTime',
        numType: 'time'
    },
]

// 运动负荷 枚举
export const Sport_Load_Pie = {
    title: [
        {
            text: '热身状态\nbpm<120',
            textStyle: {
                color: '#66A7E7',
                fontSize: '20',
                fontWeight: 'normal',
            },
            x: "10%",
            y: "bottom",
        },
        {
            text: '    持续运动\n120<bpm<180',
            textStyle: {
                color: '#66A7E7',
                fontSize: '20',
                fontWeight: 'normal',
            },
            x: "38%",
            y: "bottom",
        },
        {
            text: ' 极限预警\nbpm>180',
            textStyle: {
                color: '#66A7E7',
                fontSize: '20',
                fontWeight: 'normal',
            },
            x: "72%",
            y: "bottom",
        },
    ],
    tootip: {
        trigger: 'item',
        formatter: '{a}<br />{b}: {c} ({d}%)'
    },
    graphic: [{
        type: 'text',//控制内容为文本文字
        left: '16.5%',
        top: '31%',//调整距离盒子高处的位置
        style: {
            fill: '#02B6A5',//控制字体颜色
            text: '0人',//控制第一行字显示内容
            fontSize: '20px'
        }
    }, {
        type: 'text',//控制内容为文本文字
        left: '48%',
        top: '31%',//调整距离盒子高处的位置
        style: {
            fill: '#FF7E00',//控制字体颜色
            text: '0人',//控制第一行字显示内容
            fontSize: '20px',
        }
    }, {
        type: 'text',//控制内容为文本文字
        left: '79%',
        top: '31%',//调整距离盒子高处的位置
        style: {
            fill: '#FF0A64',//控制字体颜色
            text: '0人',//控制第一行字显示内容
            fontSize: '20px',
        }
    }],
    series: [
        {
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['20%', '35%'],
            data: [
                {
                    value: 0, name: '', itemStyle: {
                        color: '#02B6A5'
                    }

                },
                {
                    value: 0, name: '', itemStyle: {
                        color: '#3B51A5'
                    }
                }
            ],
            label: {
                show: false
            },
        },
        {
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['51%', '35%'],
            data: [
                {
                    value: 0, name: '', itemStyle: {
                        color: '#FF7E00'
                    }
                },
                {
                    value: 0, name: '', itemStyle: {
                        color: '#3B51A5'
                    }
                },
            ],
            label: {
                show: false
            },
        },
        {
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['82%', '35%'],
            data: [
                {
                    value: 0, name: '', itemStyle: {
                        color: '#FF0A64'
                    }
                },
                {
                    value: 0, name: '', itemStyle: {
                        color: '#3B51A5'
                    }
                },
            ],
            label: {
                show: false
            },
        },
    ]
}

// 平均心率 枚举
export const Average_Hear_Rate_Line = {
    xAxis: {
        type: 'category',
        data: [],
        axisLine: {
            lineStyle: {
                color: '#666666'
            }
        },
        axisTick: {
            lineStyle: {
                color: '#666666'
            }
        },
        axisLabel: {
            color: '#fff'
        },
    },
    yAxis: {
        type: 'value',
        axisLine: {
            lineStyle: {
                color: '#fff'
            }
        },
        splitLine: {
            show: true,
            lineStyle: {
                type: 'dashed',
                color: ['#62F8FE']
            }
        },
        min: 40,
        // max: 150
    },
    color: ['#4B7FE1', '#68BBC4', '#DB1794'],
    grid: {
        left: '10%',
        bottom: '10%',
        right: '5%',
        top: '16%'
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    legend: {
        data: ['平均心率', '男生平均心率', '女生平均心率'],
        textStyle: {
            color: '#fff',
            fontSize: 16
        },
        itemHeight: 18
    },
    series: [
        {
            data: [],
            type: 'line',
            smooth: true,
            name: '平均心率'
        },
        {
            data: [],
            type: 'line',
            smooth: true,
            name: '男生平均心率'
        },
        {
            data: [],
            type: 'line',
            smooth: true,
            name: '女生平均心率'
        },
    ]
}

// 运动能力情况
export const Sport_ability_Situation_Radar = {
    tooltip: {
        trigger: 'item',
    },
    radar: {
        radius: '70%',
        splitArea: {
            show: false,
            areaStyle: {
                color: "rgba(255,0,0,0)", // 图表背景的颜色
            },
        },
        alignTicks: {
            show: false,
        },
        axisLine: {
            show: false
        },
        axisLabel: {
        },
        tooltip: {
            show: true,
        },
        axisName: {
            color: '#66A7E7',
            fontSize: '16'
        },
        indicator: [
            { name: '耐力', max: 15, min: 0 },
            { name: '爆发力', max: 15, min: 0 },
            { name: '平衡', max: 15, min: 0 },
            { name: '协调', max: 15, min: 0 },
            { name: '灵敏', max: 15, min: 0 },
        ]
    },
    series: [
        {
            type: 'radar',
            data: [
                {   
                    name:'运动能力情况',
                    value: [8, 6, 7, 8, 10, 12],
                    itemStyle: {
                        color: '#5087EC'
                    }
                },
            ]
        }
    ]
}

// 动作准确度排行表格 render 使用 jsx 语法糖
export const Accuracy_Ranking_Column = [
     {
        prop: 'class_name',
        align: 'center',
        label: '班级',
    },
    {
        prop: 'name',
        align: 'center',
        label: '姓名',
    },
    {
        prop: "gender",
        align: "center",
        label: "性别",
    },
    {
        prop: 'heart_avg',
        align: 'center',
        label: '心率',
    },
]

// 中间图标显示
export const Sport_Icon = [
    {
        icon: require('../assets/images/screen_1.0.0_008.png'),
        name: '运动负荷监测',
        left: 'lb-5',
        top: 'tb-40',
        url: '/sport-load-monitoring-system'
    },
    {
        icon: require('../assets/images/screen_1.0.0_010.png'),
        name: 'AI动作分析',
        left: 'lb-20',
        top: 'tb--20',
        url: '/analysis-system'
    },
    {
        icon: require('../assets/images/screen_1.0.0_011.png'),
        name: '心率检测',
        left: 'rb-20',
        top: 'tb--10',
        url: ''
    },
    {
        icon: require('../assets/images/screen_1.0.0_009.png'),
        name: '智能评分',
        left: 'rb-10',
        top: 'tb-40',
        url: ''
    },
]

export const Ranking_Tabs_Enum = [
    {
        name:'个人排名',
        key:'person'
    },
    {
        name:'组排名',
        key:'group'
    },
]