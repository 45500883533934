<template>
  <div class="wb-100 hb-100">
    <div class="wb-100 hb-10 align-center">
      <!-- 表头 -->
      <div
        class="center hb-100 t-b1 fz-20 th"
        v-for="(item, index) in column"
        :key="index"
        :class="`wb-${Math.floor(100 / column.length)}`"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="wb-100 hb-90 auto">
      <template v-if="dataList && dataList.length">
        <TransitionGroup :name="transName" class="relative wb-100 hb-100 fz-16">
          <div
            class="tr wb-100 h-40 fz-16"
            v-for="(item, index) in dataList"
            :key="item.id"
          >
            <div class="center wb-100 hb-100">
              <div
                class="center td hb-100 t-w fz-16"
                :class="`wb-${Math.floor(100 / column.length) || 20}`"
                v-for="(column, j) in column"
                :key="j + index"
              >
                {{ item[column.prop] }}
              </div>
            </div>
          </div>
        </TransitionGroup>
      </template>

      <div class="t-w fz-20 mt-30" v-else>暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    column: {
      type: Array,
    },
    dataList: {
      type: Array,
    },
    transName:{
      type:String,
      default:'fade'
    }
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
/* 1. 声明过渡效果 */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. 声明进入和离开的状态 */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(0.3rem, 0);
}

/* 3. 确保离开的项目被移除出了布局流
      以便正确地计算移动时的动画效果。 */
.fade-leave-active {
  position: absolute;
}

.th {
  background-color: #293c82;
}
.tr {
  /* display: inline-block; */
}
.tr:nth-child(2n) {
  background-color: #293c82;
}
.tr:hover > .td {
  background-color: #14276b;
}
</style>