<template>
  <div class="wb-100 hb-100 main-bg relative hidden">
    <screen-title
      title="2024年湖南省大众蹦床嘉年华活动"
      customBg="title-box"
      titleClass="fz-35" />
    <div class="content_box wb-100 l-0 absolute align-center">
      <div class="left_box wb-25 hb-100 pr-6 pl-6 pb-5 pt-5">
        <!-- 1分钟单人单摇排行榜-男子甲组 -->
        <div class="hb-100 wb-100 mb-25">
          <jump-screen-card title="活动参赛队伍总排名" cardClass="left">
            <jump-table
              :column="singleColumn"
              :dataList="maleGroupAList"
              tableTbClass="animate__flipInX animate__animated"></jump-table>
          </jump-screen-card>
        </div>
      </div>
      <div class="center_box hb-100 pl-17 pr-17 pt-34 wb-50 hb-100">
        <!-- 总体数据 -->
        <jump-total-data
          class="wb-100 hb-20"
          :totalData="totalData"></jump-total-data>
        <!--  进行中和成绩展示 -->
        <!-- <div class="mt-8 mb-8 wb-100 center hb-5">
          <div class="hb-100 br-25 progress_bg center pl-25 pr-25 fz-22 t-w">
            进行中：1分钟单人单摇男子甲组（2组）
          </div>
        </div> -->

        <div
          class="br-20 show_bg wb-100 pt-13 pb-10 pl-24 pr-24 hb-78 mt-12 hidden">
          <div class="tw-bold fz-22 wb-100 center t-blue-79 mb-20">
            当前参赛队伍-{{ centerTitle }}
            <!-- {{ project_item.item_name }}（{{
              project_item.group_no
            }}组） -->
          </div>
          <!-- show_content -->
          <div
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.5)"
            v-loading="loading"
            class="wb-100 row-start flex-wrap">
            <!-- v-if="jumpType == 1 || jumpType == 2" -->
            <template v-if="jumpList && jumpList.length">
              <div
                class="t-w align-center column br-10 bg-blue-20 pt-7 pb-7 mb-10 w-200 h-80"
                v-for="(item, index) in jumpList"
                :key="index"
                :class="(index + 1) % 4 == 0 ? '' : 'mr-10'">
                <div class="align-center">
                  <div class="fz-30">
                    <!-- <number-animation
                      :value="item.val_result"
                      :animation_time="1"
                      numType="num"
                      class="fz-30"
                    ></number-animation> -->
                    <div class="fz-30">
                      {{ item.height ? item.height : "---" }}
                    </div>
                  </div>
                  <div class="fz-22" v-if="item.score && item.score.val_result">
                    cm
                  </div>
                </div>
                <div class="fz-20">
                  {{ item.device_no }} {{ item.student_name }}
                </div>
              </div>
            </template>
            <template v-else>
              <div class="center wb-100 hb-100 column">
                <div class="center w-127 mt-20 mb-13 h-131">
                  <img src="../assets/images/jump-no-data1.png" alt="" />
                </div>
                <div class="fz-22 no-text">暂无数据</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="right_box wb-25 hb-100 pr-6 pl-6 pb-5 pt-5">
        <!-- 1分钟单人单摇排行榜-女子甲组 -->
        <div class="hb-100 wb-100 mb-25">
          <jump-screen-card :title="rightTitle + '-成绩排名'" cardClass="right">
            <jump-table
              :column="singleColumn"
              :dataList="womanGroupAList"
              tableTbClass="animate__flipInX animate__animated"></jump-table>
          </jump-screen-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screenTitle from "../components/screenTitle.vue";
import jumpScreenCard from "../components/trampolineScreenCard.vue";
import jumpTable from "../components/trampolineTable.vue";
import jumpTotalData from "../components/trampolineTotalData.vue";
import {
  Single_Ranking_Column,
  Long_Ranking_Column,
} from "../enums/trampolineScreenEnums.js";
import axios from "axios";
import numberAnimation from "../components/numberAnimation.vue";
const instance = axios.create({
  baseURL: "http://school.sishengsports.com/api/", // 设置基础URL
});

export default {
  components: {
    screenTitle,
    jumpScreenCard,
    jumpTable,
    jumpTotalData,
    numberAnimation,
  },
  data() {
    return {
      num: 0,
      loading: false,
      centerTitle: "",
      rightTitle: "",
      singleColumn: Single_Ranking_Column,
      singleList: [],
      //  1 单摇 2 长绳
      jumpType: 1,
      jumpList: [],
      longList: [],

      longColumn: Long_Ranking_Column,
      project_item: {},

      // 男子甲组分页
      maleGroupAPage: {
        page: 1,
        per_page: 16,
        total: 40,
      },

      // 男子乙组分页
      maleGroupBPage: {
        page: 1,
        per_page: 8,
        total: 40,
      },

      // 女子甲组分页
      womanGroupAPage: {
        page: 1,
        per_page: 16,
        total: 40,
      },

      // 女子乙组分页
      womanGroupPage: {
        page: 1,
        per_page: 8,
        total: 40,
      },

      // 甲组长绳分页
      LongGroupAPage: {
        page: 1,
        per_page: 8,
        total: 40,
      },

      //乙组长绳分页
      LongGroupBPage: {
        page: 1,
        per_page: 8,
        total: 40,
      },

      // 男子甲组
      maleGroupAList: [],
      // 男子乙组
      maleGroupBList: [],
      // 女子甲组
      womanGroupAList: [],
      // 女子乙组
      womanGroupBList: [],
      // 甲组长绳
      LongGroupAList: [],
      // 乙组长绳
      LongGroupBList: [],

      requestTime: null,

      // 总体数据
      totalData: {},
    };
  },
  created() {
    // this.getQuery();

    // this.getList();
    this.getRequest();

    clearInterval(this.requestTime);
    this.requestTime = setInterval(() => {
      this.getPageList();
    }, 5 * 1000);
  },
  methods: {
    getQuery() {
      const { t } = this.$route.query;
      this.jumpType = t ? t : 1;
    },

    getPageList() {
      // 切换数据

      if (
        this.maleGroupAPage.page * this.maleGroupAPage.per_page >=
        this.maleGroupAPage.total
      ) {
        this.maleGroupAPage.page = 1;
      } else {
        this.maleGroupAPage.page += 1;
      }

      if (
        this.maleGroupBPage.page * this.maleGroupBPage.per_page >=
        this.maleGroupBPage.total
      ) {
        this.maleGroupBPage.page = 1;
      } else {
        this.maleGroupBPage.page += 1;
      }

      if (
        this.womanGroupAPage.page * this.womanGroupAPage.per_page >=
        this.womanGroupAPage.total
      ) {
        this.womanGroupAPage.page = 1;
      } else {
        this.womanGroupAPage.page += 1;
      }

      if (
        this.womanGroupPage.page * this.womanGroupPage.per_page >=
        this.womanGroupPage.total
      ) {
        this.womanGroupPage.page = 1;
      } else {
        this.womanGroupPage.page += 1;
      }

      if (
        this.LongGroupAPage.page * this.LongGroupAPage.per_page >=
        this.LongGroupAPage.total
      ) {
        this.LongGroupAPage.page = 1;
      } else {
        this.LongGroupAPage.page += 1;
      }

      if (
        this.LongGroupBPage.page * this.LongGroupBPage.per_page >=
        this.LongGroupBPage.total
      ) {
        this.LongGroupBPage.page = 1;
      } else {
        this.LongGroupBPage.page += 1;
      }

      this.getRequest();
    },

    // 请求数据
    getRequest() {
      // 总体数据
      // this.getTotalData();
      // // 成绩展示
      this.getPerformance();

      // 男子甲组
      this.getMaleGroupA();

      // 女子甲组
      this.getWomanGroupA();
    },

    async getTotalData() {
      const res = await this.axios.get("/match/allData");
      const record = res.data;
      if (res.status == 200 && record.code == 1) {
        this.totalData = record.data;
      }
    },

    async getPerformance() {
      const res = await instance.get("/bigscreen/team");
      const record = res.data;
      this.oldtraining_id = this.training_id;
      if (res.status == 200 && record.code == 1) {
        // this.totalData = record.data;
        this.num++;
        this.jumpList = record.data.team;
        this.training_id = record.data.team[0].training_id;
        if (this.num == 1) {
          this.oldtraining_id = record.data.team[0].training_id;
        }
        this.centerTitle = record.data.name;
        if (this.training_id != this.oldtraining_id) {
          this.loading = true;
        } else {
          this.loading = false;
        }
        console.log(
          this.training_id,
          this.oldtraining_id,
          "=====this.oldtraining_id"
        );
      }
    },

    async getMaleGroupA() {
      const res = await instance.get(
        "/bigscreen/schoolRank?per_page=16&page=" + this.maleGroupAPage.page
      );
      const record = res.data;
      if (res.status == 200 && record.code == 1) {
        const { data, total } = record.data;
        this.maleGroupAList = data.map((item, index) => {
          item.rank =
            (this.maleGroupAPage.page - 1) * this.maleGroupAPage.per_page +
            (index + 1);
          return item;
        });
        // this.maleGroupAPage.total = total > 40 ? 40 : total;
        console.log(total, "====总数");
        this.maleGroupAPage.total = total;
      }
    },

    async getMaleGroupB() {
      const res = await this.axios.get(
        "/match/projectRank?match_group=2&item_type=1&per_page=16&page=" +
          this.maleGroupBPage.page
      );
      const record = res.data;
      if (res.status == 200 && record.code == 1) {
        const { data, total } = record.data;
        this.maleGroupBList = data.map((item, index) => {
          item.rank =
            (this.maleGroupBPage.page - 1) * this.maleGroupBPage.per_page +
            (index + 1);
          return item;
        });
        this.maleGroupBPage.total = total > 40 ? 40 : total;
      }
    },

    async getWomanGroupA() {
      const res = await instance.get(
        "/bigscreen/studentRank?per_page=16&page=" + this.womanGroupAPage.page
      );
      const record = res.data;
      if (res.status == 200 && record.code == 1) {
        const { data, total } = record.data.rank;
        this.womanGroupAList = data.map((item, index) => {
          item.rank =
            (this.womanGroupAPage.page - 1) * this.womanGroupAPage.per_page +
            (index + 1);
          return item;
        });
        this.rightTitle = record.data.name;
        this.womanGroupAPage.total = total;
      }
    },

    async getWomanGroupB() {
      const res = await this.axios.get(
        "/match/projectRank?match_group=2&item_type=2&per_page=16&page=" +
          this.womanGroupPage.page
      );
      const record = res.data;
      if (res.status == 200 && record.code == 1) {
        const { data, total } = record.data;
        this.womanGroupBList = data.map((item, index) => {
          item.rank =
            (this.womanGroupPage.page - 1) * this.womanGroupPage.per_page +
            (index + 1);
          return item;
        });
        this.womanGroupPage.total = total > 40 ? 40 : total;
      }
    },

    async getLongGroupA() {
      const res = await this.axios.get(
        "/match/projectRank?match_group=1&item_type=3&per_page=8&page=" +
          this.LongGroupAPage.page
      );
      const record = res.data;
      if (res.status == 200 && record.code == 1) {
        const { data, total } = record.data;
        this.LongGroupAList = data.map((item, index) => {
          item.rank =
            (this.LongGroupAPage.page - 1) * this.LongGroupAPage.per_page +
            (index + 1);
          return item;
        });
        this.LongGroupAPage.total = total;
      }
    },

    async getLongGroupB() {
      const res = await this.axios.get(
        "/match/projectRank?match_group=2&item_type=3&per_page=8&page=" +
          this.LongGroupBPage.page
      );
      const record = res.data;
      if (res.status == 200 && record.code == 1) {
        const { data, total } = record.data;
        this.LongGroupBList = data.map((item, index) => {
          item.rank =
            (this.LongGroupBPage.page - 1) * this.LongGroupBPage.per_page +
            (index + 1);
          return item;
        });
        this.LongGroupBPage.total = total;
      }
    },

    getList() {
      this.jumpList = [];
      for (let index = 0; index < 12; index++) {
        let obj = {
          id: 1,
          val_result: 200,
          group_no: this.handleCompletion(index + 1),
          athlete_name: "张三",
        };
        this.jumpList.push(obj);
      }

      for (let index = 0; index < 40; index++) {
        let obj = {
          rank: index + 1,
          name: "张三" + (index + 1),
          gender: "男",
          school: "麓谷小学",
          val_result: 120,
          id: index + 1,
        };
        this.singleList.push(obj);
      }
    },
    // 数字补全
    handleCompletion(num) {
      return num > 0 && num < 10 ? "0" + num : num;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-bg {
  background: linear-gradient(
    90deg,
    #0b1145 0%,
    #10175a 28%,
    #141b66 50%,
    #10135b 80%,
    #0e0d51 100%
  );
}

.title-box {
  background: url("../assets/images/jump-title-bg.png") no-repeat;
  background-size: 100% 100%;
}

.content_box {
  height: calc(100% - 0.7rem);
  bottom: 0.2rem;
  padding: 0 0.2rem 0;
}

.left_box,
.right_box {
}

.left_box {
  border-radius: 0px 0px 0px 0.2rem;
  background: linear-gradient(
    133deg,
    rgba(12, 18, 70, 0) 0%,
    rgba(12, 18, 70, 0) 0%,
    rgba(17, 27, 96, 0.54) 75%,
    #162276 100%
  );
}

.right_box {
  border-radius: 0px 0px 0.2rem 0px;
  background: linear-gradient(
    133deg,
    rgba(12, 18, 70, 0) 0%,
    rgba(12, 18, 70, 0) 0%,
    rgba(17, 27, 96, 0.54) 75%,
    #162276 100%
  );
}

.progress_bg {
  background-color: #e4c036;
}

.show_bg {
  background-color: #143582;
  min-height: 2.53rem;
}

.show_content {
  height: calc(100% - 0.5rem);
}

.h--40 {
  height: calc(100% - 0.4rem);
}

.no-text {
  color: #1d78fd;
}
</style>
