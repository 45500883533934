import { render, staticRenderFns } from "./csgxqsx.vue?vue&type=template&id=519b3011&scoped=true&"
import script from "./csgxqsx.vue?vue&type=script&lang=js&"
export * from "./csgxqsx.vue?vue&type=script&lang=js&"
import style0 from "./csgxqsx.vue?vue&type=style&index=0&id=519b3011&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "519b3011",
  null
  
)

export default component.exports