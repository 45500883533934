<template>
  <div
    class="screenTitle flex align-center pr-15 pl-15"
    :class="isBg ? customBg ? customBg : 'titleBg' : ''"
  >
    <div class="title_left">
      <div
        class="back pointer w-110 align-center"
        v-if="isBack"
        @click="handleBack"
      >
        <div class="back_img w-22 h-37 mr-24 center">
          <img src="../assets/images/icon_back.png" alt="" />
        </div>
        <div class="fz-32 t-w">返回</div>
      </div>
    </div>
    <div class="title_center center t-w tw-bold nowrap" :class="titleClass">{{ title }}</div>
    <div class="title_right align-center row-right fz-20 mb-10">
      <div class="screen_icon w-24 center h-24 mr-14 pointer" @click="handleScreen">
        <img src="../assets/images/screen_1.0.0_006.png" alt="" srcset="" />
      </div>
      <div class="screen_time t-b1 w-200" v-if="isTime">
        {{ downTime }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "screenTitle",
  props: {
    title: {
      type: String,
      default: "智慧体育数据大屏",
    },
    isBg: {
      type: Boolean,
      default: true,
    },
    isTime: {
      type: Boolean,
      default: false,
    },
    isBack: {
      type: Boolean,
      default: false,
    },
    titleClass: {
      type: String,
      default: 'fz-48'
    },
    isBackFunction:{
      type: Boolean,
      default: false
    },
    customBg:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      downTime: "",
      downIntervalTime: null,
      isFull: false,
    };
  },
  computed: {},
  created() {
    this.handleDownTime();
  },
  mounted() {
    document.addEventListener("fullscreenchange", this.logFullScreen);
  },
  beforeDestroy() {
    documentNaNpxoveEventListener("fullscreenchange", this.logFullScreen);
  },
  methods: {
    handleScreen() {
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.requestFullScreen();
      } else {
        this.exitFullscreen();
      }
    },

    requestFullScreen() {
      let de = document.documentElement;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen();
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen();
      }
    },

    exitFullscreen() {
      let de = document;
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen();
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen();
      }
    },

    logFullScreen () {
      if (document.fullscreenElement) {
        this.isFull = true
      } else {
        this.isFull = false
      }
    },

    // 倒计时
    handleDownTime() {
      if (this.downIntervalTime) {
        clearInterval(this.downIntervalTime);
      }
      this.downIntervalTime = setInterval(() => {
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const day = new Date().getDay();
        const houer = new Date().getHours();
        const minutes = new Date().getMinutes() + 1;
        const seconds = new Date().getSeconds();

        this.downTime =
          year +
          "-" +
          this.handleZeroFill(month) +
          "-" +
          this.handleZeroFill(day) +
          " " +
          this.handleZeroFill(houer) +
          ":" +
          this.handleZeroFill(minutes) +
          ":" +
          this.handleZeroFill(seconds);
      }, 1000);
    },
    handleZeroFill(num) {
      if (num < 10) {
        return "0" + num;
      }
      return num;
    },

    // 返回
    handleBack() {
      if(this.isBackFunction){
        return this.$emit('handleBack')
      }
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}
.fz-46 {
  font-size: .46rem;
}

.fz-42 {
  font-size: .42rem;
}
.titleBg {
  background: url("../assets/images/screen_1.0.0_screen_title_bg.png") no-repeat;
  background-size: 165% 100%;
  background-position: 50% 0%;
}
.screenTitle {
  width: 100%;
  height: 90px;
  display: flex;
  .title_left,
  .title_right {
    width: calc((100% - 650px) / 2);
  }
  .title_center {
    width: 650px;
    height: 100%;
  }
}
</style>