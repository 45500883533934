export const Single_Ranking_Column = [
    {
        prop: 'rank',
        align: 'center',
        label: '排名',
        width: '10'
    },
    {
        prop: 'athlete_name',
        align: 'center',
        label: '姓名',
        width: '15'
    },
    {
        prop: 'gender',
        align: 'center',
        label: '性别',
        width: '10'
    },
    {
        prop: 'simple_name',
        align: 'center',
        label: '所属学校',
        width: '50'
    },
    {
        prop: 'val_result',
        align: 'center',
        label: '个数',
        width: '15'
    },
]

export const Long_Ranking_Column = [
    {
        prop: 'rank',
        align: 'center',
        label: '排名',
        width: '20'
    },
    {
        prop: 'simple_name',
        align: 'center',
        label: '学校名称',
        width: '60'
    },
    {
        prop: 'val_result',
        align: 'center',
        label: '个数',
        width: '20'
    },
]