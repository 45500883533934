<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    this.$nextTick(() => {
      this.resizeFun();
      window.addEventListener("resize", this.resizeFun);
    });
  },
  methods: {
    resizeFun() {
      const devicePixelRatio = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      
        // 如果在笔记本中用IE浏览器打开 则弹出提示
        if (!!window.ActiveXObject || "ActiveXObject" in window) {
          alert("请使用其他浏览器打开，不支持ie浏览器");
        } else {
          const c = document.querySelector("body");
          c.style.zoom = 1 / devicePixelRatio; // 修改页面的缩放比例
        }
    },
  },
};
</script>

<style lang='scss' scoepd>
@import "./assets/scss/common.scss";
</style>
