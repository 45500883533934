<template>
  <div class="home">
    <header>
      <div class="title_box">
        <div class="title">湘江新区香桥小学智慧体育大课间</div>
      </div>
      <div class="top_left">
        <div class="top_addess">
          <img src="~img/02.png" class="zuobiao" />
          <div>湖南长沙</div>
          <img src="~img/03.png" class="xiabiao" />
        </div>
        <div class="top_date">
          <div>2022-11-17</div>
          <div class="week">星期五</div>
        </div>
      </div>
      <div class="top_right">
        <!-- <p>长沙市望月湖第一小学</p> -->
        <div class="time">{{ clock }}</div>
        <p>欢迎您,admin</p>
        <img src="~img/04.png" @click="fillScreen" />
      </div>
    </header>
    <main>
      <div class="title">心率动态图</div>
      <div class="flexSc">
        <div
          v-for="(item, index) in colorList"
          :key="index"
          class="flexSt list_box"
        >
          <div class="color_box" :style="{ backgroundColor: item.color }"></div>
          <div class="color_title">{{ item.title }}</div>
        </div>
      </div>
      <div id="box"></div>
    </main>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      date: "",
      week: "",
      tiemInterval: null,
      clock: "",
      option: {
        animationDuration: 10000,
        xAxis: {
          interval: 0,
          type: "category",
          name: "时间",
          boundaryGap: false,
          // data: [
          //   "",
          //   "列队队形",
          //   "姿态控制操",
          //   "蹦床操",
          //   "扳腿劈叉",
          //   "素质操",
          //   "蹦床PK赛",
          //   "跑操",
          //   "放松操",

          // ],
          data: [
            "",
            "1min",
            "2min",
            "3min",
            "4min",
            "5min",
            "6min",
            "7min",
            "8min",
            "9min",
            "10min",
            "11min",
            "12min",
            "13min",
            "14min",
            "15min",
            "16min",
            "17min",
            "18min",
            "19min",
            "20min",
            "21min",
            "22min",
            "23min",
            "24min",
            "25min",
            "26min",
            "27min",
            "28min",
            "29min",
            "30min",
          ],
          nameTextStyle: {
            color: "#fff",
            fontSize: 18,
          },
          axisLabel: {
            textStyle: {
              color: "#fff",
              fontSize: 14,
              margin: 20,
            },
          },
        },
        yAxis: {
          type: "value",
          name: "心率",
          min: 0,
          max: 180,
          // boundaryGap: [0, "30%"],
          nameTextStyle: {
            color: "#fff",
            fontSize: 18,
          },
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
        },
        visualMap: {
          type: "piecewise",
          show: false,
          dimension: 0,
          seriesIndex: 0,
          pieces: [
            {
              // ge: 0,
              lte: 3,
              color: "#BBFFFF",
            },
            {
              gt: 3,
              lte: 10,
              color: "#FF8A97",
            },
            {
              gt: 10,
              lte: 12,
              color: "#73D2FF",
            },
            {
              gt: 12,
              lte: 14,
              color: "#F9E16B",
            },
            {
              gt: 14,
              lte: 17,
              color: "#1ea64b",
            },
            {
              gt: 17,
              lte: 22,
              color: "#D88F4B",
            },
            {
              gt: 22,
              lte: 26,
              color: "#4BBBD8",
            },
            {
              gt: 26,
              lte: 28,
              color: "#B58EFF",
            },
            {
              gt: 28,
              lte: 29,
              color: "#FF64FB",
            },
          ],
        },
        series: [
          {
            type: "line",
            // smooth: 0.6,
            symbol: "none",
            min: 0,
            max: 9,
            interval: 1,
            lineStyle: {
              width: 5,
            },
            markLine: {
              symbol: ["none", "none"],
              label: { show: false },
              data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }],
            },
            areaStyle: {},
            data: [],
            smooth: true,
          },
        ],
      },
      colorList: [
        { color: "#BBFFFF", title: "入场" },
        { color: "#FF8A97", title: "跑操" },
        { color: "#73D2FF", title: "队列队形训练" },
        { color: "#F9E16B", title: "姿态操" },
        { color: "#1ea64b", title: "绳操" },
        { color: "#D88F4B", title: "智慧跳绳pk赛" },
        { color: "#4BBBD8", title: "跟音乐体能操" },
        { color: "#B58EFF", title: "放松" },
        { color: "#FF64FB", title: "退场" },
      ],
      pointCount: null,
      pointTimer: null,
      mychart: null,
    };
  },
  created() {
    this.ElectronicClock();
    this.$nextTick(() => {
      // this.init();
    });
  },
  beforeDestroy() {
    clearInterval(this.tiemInterval);
  },
  methods: {
    createPoint() {
      if (this.pointCount > 33) {
        clearInterval(this.pointTimer);
        return;
      }
      switch (this.pointCount) {
        case 0:
          this.option.series[0].data.push(this.rand(70, 75));
          break;
        case 1:
          this.option.series[0].data.push(this.rand(75, 80)); //1、入场 70-100 （2分钟）
          break;
        case 2:
          this.option.series[0].data.push(this.rand(80, 90)); 
          break;
        case 3:
          this.option.series[0].data.push(this.rand(90, 100)); //跑操 120-160 （8分钟）
          break;
        case 4:
          this.option.series[0].data.push(this.rand(100, 120));
          break;
        case 5:
          this.option.series[0].data.push(this.rand(120, 130));
          break;
        case 6:
          this.option.series[0].data.push(this.rand(130, 140));
          break;
        case 7:
          this.option.series[0].data.push(this.rand(140, 150));
          break;
        case 8:
          this.option.series[0].data.push(this.rand(150, 160));
          break;
        case 9:
          this.option.series[0].data.push(this.rand(150, 155));
          break;
        case 10:
          this.option.series[0].data.push(this.rand(140, 150)); 
          break;
        case 11:
          this.option.series[0].data.push(this.rand(125, 130)); // 队列队形训练 70-90 （2分钟）
          break;
        case 12:
          this.option.series[0].data.push(this.rand(110, 120));
          break;
        case 13:
          this.option.series[0].data.push(this.rand(100, 110)); // 姿态操 100-120 （2分钟）
          break;
        case 14:
          this.option.series[0].data.push(this.rand(110, 120));
          break;
        case 15:
          this.option.series[0].data.push(this.rand(130, 135)); //五、绳操：（3分钟）心率：120-160
          break;
        case 16:
          this.option.series[0].data.push(this.rand(135, 145));
          break;
        case 17:
          this.option.series[0].data.push(this.rand(150, 160));
          break;
        case 18:
          this.option.series[0].data.push(this.rand(145, 150)); // 六、智慧跳绳pk赛：（5分钟）心率：120-160
          break;
        case 19:
          this.option.series[0].data.push(this.rand(150, 155));
          break;
        case 20:
          this.option.series[0].data.push(this.rand(155, 160));
          break;
        case 21:
          this.option.series[0].data.push(this.rand(150, 155));
          break;
        case 22:
          this.option.series[0].data.push(this.rand(140, 150));
          break;
        case 23:
          this.option.series[0].data.push(this.rand(130, 140)); //跟音乐体能操 tabata（4分钟）心率：120-160
          break;
        case 24:
          this.option.series[0].data.push(this.rand(140, 150));
          break;
        case 25:
          this.option.series[0].data.push(this.rand(150, 160));
          break;
        case 26:
          this.option.series[0].data.push(this.rand(145, 150));
          break;
        case 27:
          this.option.series[0].data.push(this.rand(120, 130)); // 八、放松 （3分钟）心率：90-100
          break;
        case 28:
          this.option.series[0].data.push(this.rand(110, 120));
          break;
        case 29:
          this.option.series[0].data.push(this.rand(90, 100));
          break;
        case 30:
          this.option.series[0].data.push(this.rand(85, 100));  // 九、退场：（1分钟）心率：70-100
        default:
          break;
      }
      // switch (this.pointCount) {
      //   case 0:
      //     this.option.series[0].data.push(80);
      //     break;
      //   case 1:
      //     this.option.series[0].data.push(100);
      //     break;
      //   case 2:
      //     this.option.series[0].data.push(120);
      //     break;
      //   case 3:
      //     this.option.series[0].data.push(125);
      //     break;
      //   case 4:
      //     this.option.series[0].data.push(130);
      //     break;
      //   case 5:
      //     this.option.series[0].data.push(170);
      //     break;
      //   case 6:
      //     this.option.series[0].data.push(150);
      //     break;
      //   case 7:
      //     this.option.series[0].data.push(100);
      //     break;

      //   default:
      //     break;
      // }
      this.myChart.setOption(this.option);
      this.pointCount++;
    },
    fillScreen() {
      //全屏
      let docElm = document.documentElement;
      //W3C
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      }

      //FireFox
      else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }

      //Chrome等
      else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }

      //IE11
      else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
      // 退出全屏
      //W3C
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      //FireFox
      else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      //Chrome等
      else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
      //IE11
      else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      // this.init();
      this.$nextTick(() => {
        // this.init();
        setTimeout(() => {
          this.init();
        }, 5000);
      });
    },
    ElectronicClock() {
      let a = new Array("日", "一", "二", "三", "四", "五", "六");
      let data = new Date().getDay();
      this.week = "星期" + a[data];
      //调用 获取日期时间
      let that = this;
      getDateTime();
      //开启定时器
      this.tiemInterval = setInterval(getDateTime, 1000);

      //定义定时器的回调函数，获取当前日期时间

      function getDateTime() {
        //创建时间，日期，对象
        let date = new Date();

        //获取年月日时分秒
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        let d = date.getDate();
        let h = date.getHours();
        let i = date.getMinutes();
        let s = date.getSeconds();

        //如果个位前面补充零
        m = m < 10 ? "0" + m : m;
        d = d < 10 ? "0" + d : d;
        h = h < 10 ? "0" + h : h;
        i = i < 10 ? "0" + i : i;
        s = s < 10 ? "0" + s : s;

        let dateTimeStr = y + "-" + m + "-" + d + " " + h + ":" + i + ":" + s;
        that.clock = h + ":" + i + ":" + s;
        that.date = y + "-" + m + "-" + d;
      }
    },
    init() {
      let dom = document.getElementById("box");
      this.myChart = echarts.init(dom);
      this.myChart.setOption(this.option);
      clearInterval(this.pointTimer);
      this.pointCount = 0;
      this.pointTimer = setInterval(() => {
        this.createPoint();
      }, 0);
    },
    rand(m, n) {
      return Math.ceil(Math.random() * (n - m + 1) + m - 1);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  background: #101b69;
  padding-top: 2.09%;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .tab_title {
    font-size: 18%;
    color: #fff;
    text-align: left;
    font-weight: bold;
  }
  .flip-list-move {
    transition: transform 1s;
  }
  .flexSt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .flexSc {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  header {
    width: 92.92%;
    height: 7.62%;
    margin: auto;
    background: url("../assets/img/01.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .title_box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 36%;
        font-weight: bold;
        color: #fff;
        text-align: center;
      }
    }
    .top_left {
      width: 28%;
      height: 100%;
      position: absolute;
      top: 0;
      padding-left: 2%;
      padding-right: 2%;
      padding-top: 0.1%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-weight: 600;
      .top_addess {
        display: flex;
        align-items: center;
        width: 35%;
        justify-content: space-between;
        .zuobiao {
          width: 12%;
        }
        div {
          font-size: 20%;
          color: #fff;
          font-weight: bold;
        }
        .xiabiao {
          width: 12%;
        }
      }
      .top_date {
        width: 45%;
        display: flex;
        justify-content: space-between;
        color: #fff;
        margin-right: 10%;
        div {
          font-size: 20%;
          font-family: Regular;
        }
        .week {
          margin-left: 5%;
        }
      }
      .time {
        font-size: 20%;
        color: #fff;
        line-height: 100%;
        font-family: Regular;
      }
    }
    .top_right {
      width: 28%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      padding-left: 2%;
      padding-right: 2%;
      padding-top: 0.1%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 20%;
      color: #fff;
      font-weight: bold;
      img {
        width: 6%;
      }
      .time {
        margin-top: 1%;
        margin-left: 10%;
      }
    }
  }

  main {
    width: 82.92%;
    height: 75.92%;
    margin: 0 auto;
    .title {
      font-size: 30%;
      font-family: AlibabaPuHuiTiB;
      color: #ffffff;
      text-align: left;
      margin: 2%;
    }

    .list_box {
      margin-right: 3%;
      .color_title {
        font-size: 20%;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        width: 80%;
        margin-left: 4px;
      }
      .color_box {
        width: 32px;
        height: 22px;
        border-radius: 6px;
      }
    }
    #box {
      width: 100%;
      height: 70vh;
      margin: 1% auto;
      margin-bottom: 0;
    }
  }
}
</style>
