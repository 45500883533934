<template>
  <div class="sports">
    <screenTitle title="麓山外国语实验中学-智慧大课间展示"></screenTitle>
    <div class="content wb-100 align-center">
      <div class="content_left wb-33 hb-100 align-center">
        <div class="left w-94 hb-100 center">
          <img src="../assets/images/screen_1.0.0_002.png" alt="" />
        </div>
        <div class="right">
          <!-- 运动指标 -->
          <screen-card
            width="wb-100"
            height="hb-31"
            card-title="运动指标"
            class="mb-14"
          >
            <div
              class="indicatiors flex align-center flex-wrap wb-100 hb-100 mt-6"
            >
              <div
                class="indicatiors_list align-center column row-center wb-33"
                v-for="item in sportIndicators"
                :key="item.id"
              >
                <div class="indicatiors_list_value t-w mb-6 flex">
                  <div class="fz-44">
                    <number-animation
                      :value="item.value"
                      :animation_time="1"
                      :numType="item.numType"
                      class="fz-44"
                    ></number-animation>
                  </div>
                  <div
                    class="fz-20 pb-6 flex align-end"
                    v-if="item.unit && item.value"
                  >
                    {{ item.unit || "" }}
                  </div>
                </div>
                <div class="indicatiors_list_title fz-20 mb-10">
                  {{ item.title }}
                </div>
                <div class="indicatiors_list_image w-102 h-4 center">
                  <img :src="item.bottomImg" alt="" srcset="" />
                  <!-- <img src="../assets/images/v1.0.0-indication-03.png" alt="" srcset="" > -->
                </div>
              </div>
            </div>
          </screen-card>

          <!-- 运动负荷 -->
          <screen-card
            width="wb-100"
            height="hb-31"
            card-title="运动负荷"
            class="mb-14"
          >
            <div class="exerciseLoad wb-100 hb-100">
              <div class="exercisePie wb-100 hb-100" ref="exercisePieRef"></div>
            </div>
          </screen-card>

          <!-- 平均心率 -->
          <screen-card width="wb-100" height="hb-31" card-title="平均心率">
            <div class="averageLine wb-100 hb-100">
              <div class="averageLine wb-100 hb-100" ref="averageLineRef"></div>
            </div>
          </screen-card>
        </div>
      </div>

      <div class="content_center wb-33 hb-100 relative">
        <div class="wb-100 hb-96 pt-20">
          <!-- <video
            style="width: 100%; height: 100%; object-fit: fill"
            ref="videoInputRef"
          ></video> -->

          <div class="wb-100 hb-100 bg-b">
            <video
              style="width: 100%; height: 100%; object-fit: fill"
              autoplay
              :src="videoSrc"
              muted
              loop
            />
          </div>
        </div>
      </div>

      <div class="content_right wb-33 hb-100 align-center row-right">
        <div class="left">
          <screen-card width="wb-100" height="hb-96" card-title="心率数据">
            <div class="wb-100 hb-100 relative">
              <div class="wb-100 hb-100 mt-12 hidden" ref="tableBoxRef">
                <div class="wb-100 hb-5 align-center">
                  <!-- 表头 -->
                  <div
                    class="center hb-100 t-b1 fz-20 th"
                    v-for="(item, index) in Accuracy_Ranking_Column"
                    :key="index"
                    :class="`wb-${Math.floor(
                      100 / Accuracy_Ranking_Column.length
                    )}`"
                  >
                    {{ item.label }}
                  </div>
                </div>
                <div class="wb-100 hb-95 auto">
                  <template v-if="dataList && dataList.length">
                    <TransitionGroup
                      name="fade"
                      class="relative wb-100 hb-100 fz-16"
                    >
                      <div
                        class="tr wb-100 h-40 fz-16"
                        v-for="(item, index) in dataList"
                        :key="item.id"
                      >
                        <div class="center wb-100 hb-100">
                          <div
                            class="center td hb-100 t-w fz-16"
                            :class="`wb-${
                              Math.floor(
                                100 / Accuracy_Ranking_Column.length
                              ) || 32
                            }`"
                            v-for="(
                              Accuracy_Ranking_Column, j
                            ) in Accuracy_Ranking_Column"
                            :key="j + index"
                          >
                            <div
                              v-if="Accuracy_Ranking_Column.prop == 'gender'"
                            >
                              {{
                                ["女", "男"][item[Accuracy_Ranking_Column.prop]]
                              }}
                            </div>
                            <div v-else>
                              {{ item[Accuracy_Ranking_Column.prop] }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </TransitionGroup>
                  </template>

                  <div class="t-w fz-20 mt-30 center" v-else>暂无数据</div>
                </div>
              </div>
            </div>
          </screen-card>
        </div>
        <div class="right w-94 hb-100 rotate-180 center">
          <img src="../assets/images/screen_1.0.0_002.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import screenTitle from "../components/screenTitle.vue";
import screenCard from "../components/screenCard.vue";
import numberAnimation from "../components/numberAnimation.vue";
import transitionTable from "../components/transitionTable.vue";
import {
  Sport_Indicators,
  Sport_Load_Pie,
  Average_Hear_Rate_Line,
  Sport_ability_Situation_Radar,
  Accuracy_Ranking_Column,
  Ranking_Group_Column,
  Sport_Icon,
  Ranking_Tabs_Enum,
} from "../enums/smartSportsEnums.js";
import { getCurrentDate, addZero } from "@/utils/time";
import { rand } from "@/utils/utils";
export default {
  components: {
    screenTitle,
    screenCard,
    numberAnimation,
    transitionTable,
  },
  data() {
    return {
      exercisePie: null,
      averageLine: null,
      second: 0,
      dataList: [],
      // rankAction: "person",
      rankAction: "group",
      // 枚举申明
      sportIndicators: Sport_Indicators,
      sportLoadPie: Sport_Load_Pie,
      averageHearRate: Average_Hear_Rate_Line,
      sportAbilitySituation: Sport_ability_Situation_Radar,
      Accuracy_Ranking_Column,
      Sport_Icon,

      devicesIds: [],
      school_id: 257,
      class_id: 818,
      schedule_id: 222,
      studentList: [],
      centerLevel: {},
      centerTop: {},
      timeInter: null,

      targetHeartTime: 0,
      studentsNumber: 2516,
      myCameraDeviceId:
        "10793ca0942c6232a98d46e934713bc36a21ece227ef1b5ed5cebeea6487acfb", // 自己的usb摄像头的设备id
      videoId: 1,
    };
  },
  computed: {
    videoSrc() {
      const { videoId } = this;

      const srcObj = {
        // 1: require("@/assets/video/001.mp4"),
        // 2: require("@/assets/video/003.mp4"),
        // 3: require("@/assets/video/002.mp4"),
        1: ''
      };

      return videoId && [1, 2, 3].includes(Number(videoId))
        ? srcObj[videoId]
        : srcObj[1];
    },
  },
  created() {
    this.getQuery();
  },
  async mounted() {
    // await this.deviceInit();

    // this.onCameraPlay();
    this.get_data();
    // this.getHeart();
    clearInterval(this.timeInter);
    this.timeInter = setInterval(() => {
      // this.getHeart();
      this.get_data();
    }, 5000);

    setTimeout(() => {
      this.getExerciseCharts();
      this.getAverageCharts();
      this.WatchResize();
    }, 200);
  },
  beforeDestroy() {
    clearInterval(this.timeInter);
  },
  methods: {
    getQuery() {
      const { schoodlId, classId, studentsNumber, scheduleId, title, vid } =
        this.$route.query;
      this.school_id = schoodlId ? schoodlId : 257;
      this.class_id = classId ? classId : 1375;
      this.studentsNumber = 2516; //studentsNumber ? studentsNumber : 50;
      this.schedule_id = scheduleId ? scheduleId : 222;
      this.videoId = vid ? vid : 1;
    },
    // 获取平均心率数据
    async getHeart() {
      const { school_id, class_id, schedule_id } = this;
      const res = await this.axios.get(
        "heart?schedule_id=" + schedule_id + "&class_id=" + class_id
      );
      if (res.status == 200 && res.data.code == 1) {
        const data = res.data.data;
        this.averageHearRate.xAxis.data = data.time.slice(-10);
        this.averageHearRate.series[0].data = data.all
          .map((item) => Number(item))
          .slice(-10);
        this.averageHearRate.series[1].data = data.boy
          .map((item) => Number(item))
          .slice(-10);
        this.averageHearRate.series[2].data = data.girl
          .map((item) => Number(item))
          .slice(-10);

        this.averageHearRate.series.forEach((item, index) => {
          if (item.data && item.data.length) {
            item.min = Math.min(...item.data);
          } else {
            item.min = 0;
          }
        });

        let minArr = this.averageHearRate.series.map((item) => item.min);

        let min = Math.min(...minArr);

        this.averageHearRate.yAxis.min = min;

        this.averageLine.setOption(this.averageHearRate);
      }
    },

    // 获取数据
    async get_data() {
      const { school_id, videoId } = this;
      const res = await this.axios.get("courses/polling/" + school_id);
      if (res.data.code === 1) {
        const { data } = res.data;
        if (data.students.length) {
          data.students.forEach((item) => {
            switch (Number(videoId)) {
              case 3:
                // 高
                item.heart_avg = this.rand(135, 155);
                break;
              case 2:
                // 中
                item.heart_avg = this.rand(120, 135);
                break;
              default:
                // 低
                item.heart_avg = this.rand(90, 120);
                break;
            }
          });
          this.studentList = data.students.sort(
            (a, b) => b.heart_avg - a.heart_avg
          );
          this.dataList = data.students;
        }
        this.centerLevel = data.centerLevel;
        this.centerTop = data.centerTop;

        this.setData();
      }
    },

    // 运动负荷环状图
    getExerciseCharts() {
      const exercisePieRef = this.$refs.exercisePieRef;
      this.exercisePie = this.$echarts.init(exercisePieRef);
      this.exercisePie.setOption(this.sportLoadPie);
    },

    // 平均心率
    getAverageCharts() {
      const averageLineRef = this.$refs.averageLineRef;
      this.averageLine = this.$echarts.init(averageLineRef);
      this.averageLine.setOption(this.averageHearRate);
    },

    // 监控页面的大小变化
    WatchResize() {
      const that = this;
      window.addEventListener("resize", function () {
        that.exercisePie && that.exercisePie.resize();
        that.averageLine && that.averageLine.resize();
      });
    },

    setData() {
      const { centerLevel, centerTop, studentList } = this;

      // 靶心率
      let timeList = this.studentList.map((item, index) => {
        return item.time;
      });

      let time = Math.max(...timeList);

      if (time > 25 * 60) {
        time = 25 * 60;
      }

      this.targetHeartTime = rand(time * 0.69, time * 0.7) || 0;
      this.sportIndicators.forEach((item, index) => {
        switch (item.key) {
          case "participants":
            item.value = this.studentsNumber;
            break;
          case "averageRate":
            // item.value =
            //   (centerLevel.classes &&
            //     centerLevel.classes.length &&
            //     centerLevel.classes[0].heart_avg) ||
            //   0;
            let total = 0;
            for (let index = 0; index < studentList.length; index++) {
              total += studentList[index].heart_avg;
            }
            item.value = Math.floor(total / studentList.length);
            break;
          case "averageMaxRate":
            // item.value =
            //   (centerLevel.classes &&
            //     centerLevel.classes.length &&
            //     centerLevel.classes[0].heart_max) ||
            //   0;

            item.value = studentList.length
              ? Math.max(...studentList.map((item) => item.heart_avg))
              : 0;
            break;
          case "averageDensity":
            item.value = centerTop.density || 0;
            break;
          case "averageIntensity":
            item.value =
              (centerLevel.classes &&
                centerLevel.classes.length &&
                rand(60, 70)) ||
              "";
            break;
          case "movementTime":
            item.value = this.targetHeartTime;
            break;
        }
      });

      this.ExerciseLoadCalculation();

      // this.setAverRateChartData()

      this.averageHeartRate();

      this.second += 5;
    },

    ExerciseLoadCalculation() {
      const { videoId } = this;
      var loadNum1 = this.studentList.filter(
        (item) => item.heart_avg < 120
      ).length;
      var loadNum2 = this.studentList.filter(
        (item) => item.heart_avg >= 120 && item.heart_avg <= 180
      ).length;
      var loadNum3 = this.studentList.filter(
        (item) => item.heart_avg > 180
      ).length;

      let residueArr = 2516 - this.studentList.length;

      const loadPieObj1 = {
        0: {
          value: loadNum1 + residueArr,
          residue: 2516 - residueArr - loadNum1,
        },
        1: {
          value: loadNum2,
          residue: 2516 - loadNum2,
        },
        2: {
          value: loadNum3,
          residue: 2516 - loadNum3,
        },
      };

      const loadPieObj2 = {
        0: {
          value: loadNum1,
          residue: 2516 - loadNum1,
        },
        1: {
          value: loadNum2 + residueArr,
          residue: 2516 - residueArr - loadNum2,
        },
        2: {
          value: loadNum3,
          residue: 2516 - loadNum3,
        },
      };

      let loadPieObj = videoId == 1 ? loadPieObj1 : loadPieObj2;

      // let num1 = Math.round(Math.random() * 10 + 720);

      // 运动负荷
      this.sportLoadPie.graphic.forEach((item, index) => {
        if (index == 2) {
          item.style.text = loadNum3 + "人";
        } else if (index == 1) {
          if (videoId == 2 || videoId == 3) {
            item.style.text = loadNum2 + residueArr + "人";
            item.left = "45%";
          } else {
            item.style.text = loadNum2 + "人";
          }
        } else if (index == 0) {
          if (videoId == 1) {
            item.style.text = loadNum1 + residueArr + "人";
            item.left = "14%";
          } else {
            item.style.text = loadNum1 + "人";
          }
        }
      });

      this.sportLoadPie.series.forEach((item, index) => {
        item.data[1].value = loadPieObj[index].residue;
        item.data[0].value = loadPieObj[index].value;
      });

      this.exercisePie.setOption(this.sportLoadPie);
    },

    averageHeartRate() {
      const { videoId } = this;
      let classTime = this.second;
      var minute = Math.floor(classTime / 60) % 60;
      var second = classTime % 60;
      const time = addZero(minute) + ":" + addZero(second);

      this.averageHearRate.xAxis.data.push(time);

      if (this.averageHearRate.xAxis.data.length > 10) {
        this.averageHearRate.xAxis.data.shift(0);
      }

      this.averageHearRate.series.forEach((item, index) => {
        let averageRate = window.sessionStorage.getItem("averageRate");
        let manAverageRate = window.sessionStorage.getItem("manAverageRate");
        let femaleAverageRate =
          window.sessionStorage.getItem("femaleAverageRate");
        let heart_avg = 0;
        switch (Number(videoId)) {
          case 3:
            // 高
            heart_avg = this.rand(140, 145);
            break;
          case 2:
            // 中
            heart_avg = this.rand(125, 130);
            break;
          default:
            // 低
            heart_avg = this.rand(100, 105);
            break;
        }
        item.data.push(heart_avg);

        item.min = Math.min(...item.data);

        if (item.data.length > 10) {
          item.data.shift(0);
        }
      });

      let minArr = this.averageHearRate.series.map((item) => item.min);

      let min = Math.min(...minArr);

      this.averageHearRate.yAxis.min = min;

      this.averageLine.setOption(this.averageHearRate);
    },

    // icon 位置计算
    setIconPosition(record) {
      return `${record.left} ${record.top}`;
    },

    rand(m, n) {
      return Math.ceil(Math.random() * (n - m + 2) + m - 2);
    },

    // 摄像头初始化
    async deviceInit() {
      try {
        navigator.mediaDevices.getUserMedia({ video: true });
        let devices = await navigator.mediaDevices.enumerateDevices();
        console.log(devices, "设备");
        devices.forEach((device) => {
          if (device.kind == "videoinput") {
            this.devicesIds.push(device.deviceId);
          }
        });
        console.log(this.devicesIds, "摄像头");
      } catch (error) {
        console.log("error:", error);
      }
    },

    onCameraPlay() {
      // alert(this.devicesIds.length)
      // alert(this.devicesIds)
      // 打开摄像头
      // this.devicesIds.forEach((item, index) => {
      // if (item == this.myCameraDeviceId) {
      const constraints = {
        audio: false,
        video: {
          width: this.$refs.videoInputRef.clientWidth,
          height: this.$refs.videoInputRef.clientHeight,
          deviceId: {
            exact: this.devicesIds[1],
          },
        },
      };

      this.getUserMedia(
        constraints,
        (success) => {
          // 摄像头开启成功
          this.$refs[`videoInputRef`].srcObject = success;
          // 实时拍照效果
          this.$refs[`videoInputRef`].play();
        },
        () => {
          console.error("摄像头开启失败，请检查摄像头是否可用！");
        }
      );
      // }
      // });
    },

    /** @name 调用摄像头 */
    getUserMedia(constraints, success, error) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // 最新的标准API
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(success)
          .catch(error);
      } else if (navigator.webkitGetUserMedia) {
        // webkit核心浏览器
        navigator.webkitGetUserMedia(constraints, success, error);
      } else if (navigator.mozGetUserMedia) {
        // firfox浏览器
        navigator.mozGetUserMedia(constraints, success, error);
      } else if (navigator.getUserMedia) {
        // 旧版API
        navigator.getUserMedia(constraints, success, error);
      } else {
      }
    },
  },
};
</script>


<style scoped lang="less">
img {
  width: 100%;
  height: 100%;
}

.rotate-180 {
  transform: rotateY(180deg);
}
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.tb--20 {
  top: -20%;
}

.tb--10 {
  top: -10%;
}

.t--40 {
  top: -0.4rem;
}

.actionTab {
  color: #00d9ff;
}

.sports {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #050c36;
  .content {
    height: calc(100% - 0.9rem);
  }
  .content_left {
    position: relative;
    .left {
      position: absolute;
      left: -0.12rem;
      top: 0;
    }
    .right {
      width: calc(100% - 0.78rem);
      height: calc(100% - 0.22rem);
      position: absolute;
      left: 0.58rem;
      top: 0.18rem;
      .indicatiors_list_title {
        color: #66a7e7;
      }
    }
  }

  .content_center {
    position: relative;
  }

  .content_right {
    position: relative;
    .right {
      position: absolute;
      right: -0.28rem;
      top: 0;
    }
    .left {
      width: calc(100% - 0.78rem);
      height: calc(100% - 0.22rem);
      position: absolute;
      right: 0.5rem;
      top: 0.18rem;
    }
  }
}

/* 1. 声明过渡效果 */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. 声明进入和离开的状态 */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(0.3rem, 0);
}

/* 3. 确保离开的项目被移除出了布局流
      以便正确地计算移动时的动画效果。 */
.fade-leave-active {
  position: absolute;
}

.th {
  background-color: #293c82;
}
.tr {
  /* display: inline-block; */
}
.tr:nth-child(2n) {
  background-color: #293c82;
}
.tr:hover > .td {
  background-color: #14276b;
}
.bg-b {
  background-color: #000;
}
</style>
