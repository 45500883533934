<template>
  <div class="card pt-14 pl-16 pr-18 pb-24" :class="className">
    <div class="card_title t-w fz-23 align-center h-30 wb-100">
        {{ cardTitle }}
    </div>
    <div class="contain wb-100 h-z">
        <slot></slot>
    </div>
    <div class="card_left_top"></div>
    <div class="card_left_bottom"></div>
    <div class="card_right_top"></div>
    <div class="card_right_bottom"></div>
  </div>
</template>

<script>
export default {
  name: "screenCard",
  props: {
    width: {
      type: String,
      default: "wb-100",
    },
    height: {
      type: String,
      default: "wh-33",
    },
    cardTitle:{
        type:String,
        default:''
    }
  },
  data() {
    return {};
  },
  computed: {
    className(){
        const { width, height } = this
        return  `${width} ${height}`
    }
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.h-z {
    height: calc(100% - .3rem);
}
.card {
  background-color: #14276b;
  position: relative;
  border: 0.02rem solid #135da9;
  .card_left_top,
  .card_left_bottom,
  .card_right_top,
  .card_right_bottom {
    width: 0.13rem;
    height: 0.13rem;
    position: absolute;
    border: 0.02rem solid transparent;
  }

  .card_left_top {
    top: -0.02rem;
    left: -0.02rem;
    border-left-color: #62f9ff;
    border-top-color: #62f9ff;
  }

  .card_left_bottom {
    left: -0.02rem;
    bottom: -0.02rem;
    border-left-color: #62f9ff;
    border-bottom-color: #62f9ff;
  }

  .card_right_top {
    right: -0.02rem;
    top: -0.02rem;
    border-right-color: #62f9ff;
    border-top-color: #62f9ff;
  }

  .card_right_bottom {
    right: -0.02rem;
    bottom: -0.02rem;
    border-right-color: #62f9ff;
    border-bottom-color: #62f9ff;
  }
}
</style>