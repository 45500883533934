import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import store from "./store";
import less from "less";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import * as echarts from "echarts";
import "style/base.css";
import animated from "animate.css";
// import { watchFun } from "@/utils/detectZoom.js";
// watchFun();
// window.addEventListener("resize", watchFun);

Vue.config.productionTip = false;

Vue.prototype.axios = axios;
axios.defaults.baseURL = "http://oldapp.sishengsports.com/api/";
// axios.defaults.baseURL = "http://school.sishengsports.com/api/";
//school.sishengsports.com/

http: Vue.prototype.$echarts = echarts;
Vue.use(animated);
Vue.use(ElementUI);
Vue.use(less);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
