<template>
  <div class="login">
    <img
      src="https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/web/school_login.png"
    />
    <div class="login_box">
      <h2>欢迎登录智慧大屏</h2>
      <el-form
        label-position="top"
        ref="form"
        :rules="rules"
        :hide-required-asterisk="true"
        label-width="80px"
        :model="form"
      >
        <el-form-item label="登录账号" prop="username">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" show-password></el-input>
        </el-form-item>

        <el-form-item>
          <el-checkbox v-model="checked">记住密码</el-checkbox>
          <!-- <span>忘记密码?</span> -->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round @click="login">立即登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="title">
      <h1>智慧体育服务系统</h1>
      <h4>Intelligent sports service system</h4>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    const checkFinanceCode = (rule, value, callback) => {
      if (value) {
        // var reg =
        //   /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F])[\da-zA-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]{8,}$/;

        var reg =
          /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F])[\da-zA-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]{8,}$/;
        if (reg.test(value) === false) {
          callback(new Error("密码至少为8位,包含数字、大小写字母以及特殊字符"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      form: {
        username: "",
        password: "",
      },
      checked: false,

      rules: {
        username: [
          // required: 非空规则
          // message: 提示信息
          // trigger: 触发的方式  blur  change
          { required: true, message: "请输入登录账号", trigger: "blur" },
        ],
        password: [
          {
            min: 8,
            message: "密码至少为8位,包含数字、大小写字母以及特殊字符",
            trigger: "change",
            validator: checkFinanceCode,
          },
          {
            required: true,
            message: "密码至少为8位,包含数字、大小写字母以及特殊字符",
            trigger: "blur",
            validator: checkFinanceCode,
          },
        ],
      },

      _axios: null,
    };
  },
  created() {
    this._axios = axios.create({
      baseURL: "http://school.sishengsports.com", //baseURL会在发送请求时拼接在url参数前面
      timeout: 30000,
    });
  },
  methods: {
    handleChaecPwd(val) {
      let finalCheckPwd =
        /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F])[\da-zA-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]{8,}$/;

      return finalCheckPwd.test(val);
    },
    async login() {
      if (this.handleChaecPwd(this.form.password)) {
        try {
          await this.$refs.form.validate();
          const params = {
            row: { ...this.form },
          };

          const res = await this._axios({
            url: "/api/auth/login",
            data: params,
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            method: "POST",
          });

          console.log(res.data);
          if (res.data.code === 1) {
            localStorage.setItem("token", res.data.data.token);

            const path = sessionStorage.getItem("path");
            const query = JSON.parse(sessionStorage.getItem("query"));
            const keyValuePairs = [];
            if (path && path !== "/login") {
              for (let key in query) {
                if (query.hasOwnProperty(key)) {
                  let value = query[key];
                  if (typeof value === "object") {
                    value = JSON.stringify(value);
                  }
                  keyValuePairs.push(`${key}=${value}`);
                }
              }
              const str = "?" + keyValuePairs.join("&");

              this.$router.push(path + str);
            } else {
              this.$router.push("/");
            }
          } else {
            this.$message.error(res.data.msg);
          }
        } catch (e) {
          console.log(e, "e");
          return false;
        }
      } else {
        this.$message.warning("密码至少为8位,包含数字、大小写字母以及特殊字符");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  img {
    width: 55.21%;
    height: 100%;
  }
  .login_box {
    height: 100%;
    width: 44.79%;
    padding-top: 191px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-size: 40px;
      font-weight: bold;
      color: #2d3a73;
    }
    .el-form {
      width: 570px;
      margin-top: 62px;
      span {
        float: right;
        cursor: pointer;
        font-size: 15px;
        font-weight: 400;
        color: #537af2;
      }
    }
  }
  .title {
    position: absolute;
    top: 81px;
    left: 92px;
    h1 {
      font-size: 61px;
      font-weight: bold;
      color: #ffffff;
      line-height: 71px;
    }
    h4 {
      font-size: 33px;
      font-weight: 300;
      color: #ffffff;
      line-height: 39px;
    }
  }
}

::v-deep.el-button {
  width: 100%;
  height: 50px;
  border-radius: 50px;
  font-size: 20px;
  background: #537af2;
  font-weight: 500;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  margin-top: 70px;
}

::v-deep .el-form-item__label {
  display: flex;
  justify-content: flex-start;
  font-size: 0.2rem;
}

::v-deep .el-form-item__content {
  display: flex;
  justify-content: flex-start;
  font-size: 0.2rem;
}
</style>
