<template>
  <div class="home">
    <header>
      <div class="title_box">
        <div class="title">长沙市恒定高级中学-智慧大课间展示</div>
      </div>
      <div class="top_left">
        <div class="top_addess">
          <img src="~img/02.png" class="zuobiao" />
          <div>湖南长沙</div>
          <img src="~img/03.png" class="xiabiao" />
        </div>
        <div class="top_date">
          <div>{{ date }}</div>
          <div class="week">{{ week }}</div>
        </div>
      </div>
      <div class="top_right">
        <!-- <p>长沙市望月湖第一小学</p> -->
        <div class="time">{{ clock }}</div>
        <p>欢迎您,admin</p>
        <img src="~img/04.png" @click="fillScreen" />
      </div>
    </header>
    <main>
      <div class="left_achievement">
        <!-- aaaaa -->
        <div class="personal_achievement">
          <p class="tab_title">心率实时数据</p>
          <ul class="tab_personal_top">
            <li class="bh">班号</li>
            <li class="num">姓名</li>
            <li>性别</li>
            <li>年级</li>
            <li class="num">班级</li>
            <li class="num">时长</li>
            <li class="num">心率</li>
            <li class="sem">体温</li>
          </ul>
          <div class="ul_box" v-if="students">
            <transition-group name="flip-list" tag="ul">
              <ul
                class="tab_personal_body"
                v-for="item in students"
                :key="item.id"
              >
                <li class="bh2">{{ item.number }}</li>
                <li class="num">{{ item.name }}</li>
                <li v-if="item.gender === 1">男</li>
                <li v-else>女</li>
                <li>{{ item.level_name }}</li>
                <li class="num">{{ item.class_name }}</li>
                <!-- 时长 -->
                <li class="num">
                  {{ item.time > 0 ? Math.ceil(item.time / 60) : "- -" }}分钟
                </li>
                <li class="num" v-if="item.heart_avg > 0">
                  {{ item.heart_avg }}
                </li>
                <li class="num" v-else>- -</li>
                <li class="sem" v-if="item.temp > 0">{{ item.temp }}</li>
                <li class="sem" v-else>- -</li>
              </ul>
            </transition-group>
          </div>
        </div>
      </div>
      <div class="center_box">
        <ul class="number_box">
          <li>
            <img src="../assets/img/29.png" />
            <p class="p1">1540人</p>
            <p class="p2">参赛人数</p>
          </li>

          <!--          <li>
            <img src="../assets/img/29.png" />
            <p class="p1">2个</p>
            <p class="p2">蹦床数量</p>
          </li> -->
          <li>
            <img src="../assets/img/29.png" />
            <p class="p1">{{ centerTop.time }}小时</p>
            <p class="p2">运动时长</p>
          </li>
        </ul>
        <div class="xl_center_box">
          <div class="xl_box_list">
            <div class="xl_list_item">
              <img src="../assets/img/33.png" alt="" />
              <div>
                <p class="title">心率达标</p>
                <p class="num">{{ centerTop.heart }}%</p>
              </div>
            </div>

            <div class="xl_list_item">
              <img src="../assets/img/32.png" alt="" />
              <div>
                <p class="title">运动密度</p>
                <p class="num">
                  {{ centerTop.density > 0 ? centerTop.density : "- -" }}%
                </p>
              </div>
            </div>
          </div>

          <div class="xl_box_list xl_box_list_tw">
            <div class="xl_list_item">
              <img src="../assets/img/30.png" alt="" />
              <div>
                <p class="title">平均心率</p>
                <p class="num">
                  {{ averageRate || "---" }}
                </p>
              </div>
            </div>

            <div class="xl_list_item">
              <img src="../assets/img/31.png" alt="" />
              <div>
                <p class="title">平均体温</p>
                <p class="num">
                  {{ averageTmpe || '---' }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="photo">
          <video
            src=""
            muted
            loop="loop"
            autoplay="autoplay"
            poster="https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/hdzx.jpg"
          />
          <img src="../assets/img/20.png" class="bian_kuang" />
        </div>
        <div class="xinlv_bottom_box" v-if="centerGroup">
          <img src="~img/17.png" />
          <!-- <p class="group_bottom_name">第{{ centerGroup.group }}组</p> -->
          <ul class="xinlv_ul">
            <li class="test">
              <p class="p3">
                <!-- {{ centerGroup.heart_avg > 0 ? centerGroup.heart_avg : "- -" }} -->
                {{ averageRate || "---" }}
                BPM
              </p>
              <p class="p4">平均心率</p>
            </li>
            <li class="test">
              <p class="p3">
                <!-- {{ centerGroup.heart_max > 0 ? centerGroup.heart_max : "- -" }} -->
                {{ Math.max(...students.map(item => item.heart_avg)) || '---' }}
                BPM
              </p>
              <p class="p4">最高心率</p>
            </li>
            <li class="test">
              <p class="p3">
                <!-- {{ centerGroup.heart_min > 0 ? centerGroup.heart_min : "- -" }} -->
                {{ Math.min(...students.map(item => item.heart_avg)) || '---' }}
                BPM
              </p>
              <p class="p4">最低心率</p>
            </li>
            <!-- <li class="test">
              <p class="p3">
                {{ centerGroup.kcal > 0 ? centerGroup.kcal : "- -" }} C
              </p>
              <p class="p4">消耗卡路里</p>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="right_achievement">
        <div class="achievement_box aaaa">
          <p class="tab_title">个人TOP榜</p>
          <ul class="tab_personal_right">
            <li style="width: 18%; text-align: center">排名</li>
            <li class="bod" style="width: 18%; text-align: center">姓名</li>
            <li class="bod nj" style="width: 18%; text-align: center">年级</li>
            <li class="bod" style="width: 18%; text-align: center">运动时长</li>
            <li class="bod" style="width: 18%; text-align: center">平均心率</li>
            <li class="bod tw" style="width: 18%; text-align: center">体温</li>
          </ul>
          <ul class="tab_body" v-if="students">
            <transition-group name="flip-list" tag="ul">
              <li v-for="(item, index) in students" :key="item.id">
                <div style="width: 18%" class="center">
                  <img src="~img/23.png" v-if="index === 0" />
                  <img src="~img/24.png" v-else-if="index === 1" />
                  <img src="~img/25.png" v-else-if="index === 2" />
                  <div v-else>
                    {{ index + 1 }}
                  </div>
                </div>
                <p class="bod name" style="width: 18%; text-align: center">
                  {{ item.name }}
                </p>
                <p class="nj" style="width: 18%; text-align: center">
                  {{ item.level_name }}
                </p>
                <p style="width: 18%; text-align: center">
                  {{ item.time > 0 ? Math.ceil(item.time / 60) : "- -" }} 分钟
                </p>
                <p
                  style="width: 18%; text-align: center"
                  v-if="item.heart_avg > 0"
                >
                  {{ item.heart_avg }}
                </p>
                <p style="width: 18%; text-align: center" v-else>- -</p>
                <p
                  class="tw"
                  v-if="item.temp > 0"
                  style="width: 18%; text-align: center"
                >
                  {{ item.temp }}
                </p>
                <p style="width: 18%; text-align: center" v-else>- -</p>
              </li>
            </transition-group>
          </ul>
        </div>
        <!-- <div class="achievement_box">
          <p class="tab_title">小组TOP榜</p>
          <ul class="tab_personal_right">
            <li>排名</li>
            <li class="bod">分组</li>
            <li class="bod">运动时长</li>
            <li class="bod">平均心率</li>
            <li class="bod">体温</li>
            <li class="bod">卡路里</li>
          </ul>
          <ul class="tab_body" v-if="topGroup">
            <transition-group name="flip-list" tag="ul">
              <li v-for="(item, index) in topGroup" :key="item.id">
                <img src="~img/23.png" v-if="index === 0" />
                <img src="~img/24.png" v-else-if="index === 1" />
                <img src="~img/25.png" v-else-if="index === 2" />
                <p class="paming" v-else>{{ index + 1 }}</p>
                <p>{{ item.group }}</p>

                <p>
                  {{ item.time > 0 ? Math.ceil(item.time / 3600) : "- -" }} 小时
                </p>
                <p v-if="item.heart_avg > 0">{{ item.heart_avg }}</p>
                <p v-else>- -</p>
                <p v-if="item.temp > 0">{{ item.temp }}</p>
                <p v-else>- -</p>
                <p>{{ item.kcal > 0 ? item.kcal : "- -" }}</p>
              </li>
            </transition-group>
          </ul>
        </div>
        <div class="achievement_box">
          <p class="tab_title">班级TOP榜</p>
          <ul class="tab_personal_right">
            <li>排名</li>
            <li class="bod">年级</li>
            <li class="bod">班级</li>
            <li class="bod">运动时长</li>
            <li class="bod">平均心率</li>
            <li class="bod">卡路里</li>
          </ul>
          <ul class="tab_body" v-if="topClasses">
            <transition-group name="flip-list" tag="ul">
              <li v-for="(item, index) in topClasses" :key="item.id">
                <img src="~img/23.png" v-if="index === 0" />
                <img src="~img/24.png" v-else-if="index === 1" />
                <img src="~img/25.png" v-else-if="index === 2" />
                <p class="paming" v-else>{{ index + 1 }}</p>
                <p>{{ item.level_name }}</p>
                <p>{{ item.class_name }}</p>

                <p>
                  {{ item.time > 0 ? Math.ceil(item.time / 3600) : "- -" }} 小时
                </p>
                <p v-if="item.heart_avg > 0">{{ item.heart_avg }}</p>
                <p v-else>- -</p>
                <p>{{ item.kcal > 0 ? item.kcal : "- -" }}</p>
              </li>
            </transition-group>
          </ul>
        </div> -->
      </div>
    </main>
  </div>
</template>

<script>
import { rand } from "@/utils/utils.js";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      timeInter: null,
      opt: {
        audio: false,
        video: {
          width: "100%",
          height: "100%",
          facingMode: "environment",
        },
      },
      play_box: true,
      topLevels: [], // 班级排名top榜
      students: [],
      centerLevel: [],
      centerGroup: [],
      topStudent: [],
      topGroup: [],
      topClasses: [],
      topCount: 0,
      topStudent1: [],
      topStudentGrade1: [3, 3, 3, 4, 4],
      topGroup1: [6, 7, 10, 11, 13, 1],
      tiemInterval: null,
      clock: "",
      date: "",
      week: "",
      centerTop: {
        heart_avg: "",
        heart: "",
        temp_avg: "",
        time: "",
        density: "",
      },
    };
  },
  computed: {
    averageRate() {
      const { students } = this;
      let total = 0;
      const arr = students.filter(item => item.heart_avg)
      for (let index = 0; index < arr.length; index++) {
        total += arr[index].heart_avg;
      }
      return Math.floor(total / arr.length);
    },
    averageTmpe() {
      const { students } = this;
      let total = 0;
      const arr = students.filter(item => item.temp)
      for (let index = 0; index < arr.length; index++) {
        total += arr[index].temp * 1;
      }

      return (total / arr.length).toFixed(1);
    },
  },
  created() {
    //   this.connectWebsocket();
    this.get_data();
    // let video = document.querySelector("video");
    // viode.play();
  },
  mounted() {
    this.ElectronicClock();
    // let video = document.querySelector("video");
    // video.play();
    clearInterval(this.timeInter);
    this.timeInter = setInterval(() => {
      this.get_data();
    }, 5000);
    //  this.ElectronicClock();
  },
  beforeDestroy() {
    clearInterval(this.tiemInterval);
    clearInterval(this.timeInter);
  },
  methods: {
    async get_data() {
      const res = await this.axios.get("courses/polling/257");
      if (res.data.code === 1) {
        const { data } = res.data;
        console.log(data);
        this.topLevels = data.topLevels;
        this.students = data.students;
        this.centerLevel = data.centerLevel;
        this.centerLevel.classes = this.centerLevel.classes.slice(0, 4);
        this.centerGroup = data.centerGroup[0];
        this.topStudent = data.topStudent;
        this.topGroup = data.topGroup;
        this.topClasses = data.topClasses;
        this.centerTop = data.centerTop;
        this.centerTop.heart = Math.round(Math.random() * 10 + 70);

        this.ruleSetData();

        this.topLevels.sort(function (a, b) {
          return b.heart_avg - a.heart_avg;
        });
        this.students.sort(function (a, b) {
          return b.heart_avg - a.heart_avg;
        });
        this.topStudent.sort(function (a, b) {
          return b.heart_avg - a.heart_avg;
        });
        this.topClasses.sort(function (a, b) {
          return b.heart_avg - a.heart_avg;
        });

        // console.log(this.students,'this.students')

        // if (this.students) {
        //   this.topStudent = this.students.slice(0, 30);
        // }
      }
    },
    // 规则复制
    ruleSetData() {
      // heart_avg
      this.students.forEach((item) => {
        item.temp = this.footRand(36.1, 36.5);
        item.heart_avg = rand(135, 155) - rand(2, 5);
        item.time = 16 * 60;
        this.topTime = 16 * 60;
      });
    },
    getmediaDevices() {
      let video = document.querySelector("video");
      video.setAttribute("autoplay", "");
      video.setAttribute("muted", "");
      video.setAttribute("playsinline", "");
      navigator.mediaDevices
        .getUserMedia(this.opt)
        .then(function (mediaStream) {
          video.srcObject = mediaStream;
          video.onloadedmetadata = function (e) {
            video.play();
          };
        })
        .catch(function (err) {
          console.log(err.name + ": " + err.message);
          alert(err.name + "" + err.message);
        }); // always check for errors at the end.
    },
    fillScreen() {
      //全屏
      let docElm = document.documentElement;
      //W3C
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      }

      //FireFox
      else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }

      //Chrome等
      else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }

      //IE11
      else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
      // 退出全屏
      //W3C
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      //FireFox
      else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      //Chrome等
      else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
      //IE11
      else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    connectWebsocket() {
      let _this = this;
      let websocket;
      let protocol = "wss";
      if (typeof WebSocket === "undefined") {
        console.log("您的浏览器不支持WebSocket");
        return;
      } else {
        let url = "";
        if (window.location.protocol == "https:") {
          protocol = "wss";
        }
        // `${protocol}://window.location.host/echo`;
        // 47.105.134.85:2348
        // url = `${protocol}://47.105.134.85:2348`;
        url = `${protocol}://screen.sishengsports.com/wss/`;

        // 打开一个websocket
        websocket = new WebSocket(url);
        // 建立连接
        websocket.onopen = () => {
          // 发送数据
          console.log("websocket发送数据中");
        };
        // 客户端接收服务端返回的数据
        websocket.onmessage = (evt) => {
          console.log("websocket返回的数据：", evt);
          let data = JSON.parse(evt.data);
          console.log(data);
          if (data.type && data.type === "ping") {
            return false;
          } else {
            try {
              this.topLevels = data.data.topLevels;
              this.students = data.data.students;
              this.centerLevel = data.data.centerLevel;
              this.centerLevel.classes = this.centerLevel.classes.slice(0, 4);
              this.centerGroup = data.data.centerGroup[0];
              this.topStudent = data.data.topStudent;
              this.topGroup = data.data.topGroup;
              this.topClasses = data.data.topClasses;
              this.centerTop = data.data.centerTop;
              // if(_this.topCount>1){
              //  for(let i=0;i<5;i++){
              //   _this.topStudent[i].name=_this.topStudent1[i];
              //    _this.topStudent[i].level_name = _this.topStudentGrade1[i];
              //    _this.topGroup[i].group = _this.topGroup1[i];
              //  }
              // }
              // _this.topCount++;
              console.log(this.centerGroup);
            } catch (err) {
              return err;
            }
          }
        };
        // 发生错误时
        websocket.onerror = (evt) => {
          console.log("websocket错误：", evt);
        };
        // 关闭连接
        websocket.onclose = (evt) => {
          console.log("websocket关闭：", evt);
        };
      }
    },
    footRand(n, m) {
      return (Math.random() * (n - m + 0.1) + m - 0.1).toFixed(1);
    },
    ElectronicClock() {
      let a = new Array("日", "一", "二", "三", "四", "五", "六");
      //创建时间，日期，对象
      let date = new Date();

      date.setDate(date.getDate() - 3);
      let data = date.getDay();

      this.week = "星期" + a[data];
      //调用 获取日期时间
      let that = this;
      getDateTime();
      //开启定时器
      this.tiemInterval = setInterval(getDateTime, 1000);

      //定义定时器的回调函数，获取当前日期时间

      function getDateTime() {

        //获取年月日时分秒
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        let d = date.getDate();
        let h = date.getHours();
        let i = date.getMinutes();
        let s = date.getSeconds();

        //如果个位前面补充零
        m = m < 10 ? "0" + m : m;
        d = d < 10 ? "0" + d : d;
        h = h < 10 ? "0" + h : h;
        i = i < 10 ? "0" + i : i;
        s = s < 10 ? "0" + s : s;

        let dateTimeStr = y + "-" + m + "-" + d + " " + h + ":" + i + ":" + s;
        that.clock = h + ":" + i + ":" + s;
        that.date = y + "-" + m + "-" + d;
      }
    },
    Turnonthecamera() {
      this.play_box = false;
      this.getmediaDevices();
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home {
  width: 100%;
  height: 100%;
  background: #101b69;
  padding-top: 2.09%;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .tab_title {
    font-size: 18%;
    color: #fff;
    text-align: left;
    font-weight: bold;
  }
  .flip-list-move {
    transition: transform 1s;
  }

  header {
    width: 92.92%;
    height: 7.62%;
    margin: auto;
    background: url("../assets/img/01.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .title_box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 36%;
        font-weight: bold;
        color: #fff;
        text-align: center;
      }
    }
    .top_left {
      width: 28%;
      height: 100%;
      position: absolute;
      top: 0;
      padding-left: 2%;
      padding-right: 2%;
      padding-top: 0.1%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-weight: 600;
      .top_addess {
        display: flex;
        align-items: center;
        width: 35%;
        justify-content: space-between;
        .zuobiao {
          width: 12%;
        }
        div {
          font-size: 20%;
          color: #fff;
          font-weight: bold;
        }
        .xiabiao {
          width: 12%;
        }
      }
      .top_date {
        width: 45%;
        display: flex;
        justify-content: space-between;
        color: #fff;
        margin-right: 10%;
        div {
          font-size: 20%;
          font-family: Regular;
        }
        .week{
          margin-left: 5%;
        }
      }
      .time {
        font-size: 20%;
        color: #fff;
        line-height: 100%;
        font-family: Regular;


      }
    }
    .top_right {
      width: 28%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      padding-left: 2%;
      padding-right: 2%;
      padding-top: 0.1%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 20%;
      color: #fff;
      font-weight: bold;
      img {
        width: 6%;
      }
      .time{
        margin-top:1%;
        margin-left: 10%;
      }
    }
  }
  main {
    display: flex;
    justify-content: space-between;
    height: 92%;
    .left_achievement {
      width: 33%;
      height: 100%;
      padding-top: 2%;
      padding-left: 3.5%;
      .grade_achievement {
        width: 100%;
        height: 25%;
        background: url("../assets/img/05.png") no-repeat;
        background-size: 100% 100%;
        padding: 3%;
        padding-left: 2%;
        padding-right: 2%;
        .tab_top {
          display: flex;
          margin-top: 2%;
          justify-content: space-between;
          li {
            font-size: 16%;
            color: #fff;
            flex-grow: 1;
          }
          .first {
            flex-grow: 2.7;
            text-align: left;
            padding-left: 2%;
          }
        }
        .tab_body {
          height: 93%;
          // height: 80%;
          padding-top: 2%;

          li {
            width: 100%;
            padding-top: 1%;
            padding-bottom: 1%;
            background: url("../assets/img/07.png") no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2%;
            padding-left: 2%;

            img {
              width: 6%;
              margin-right: 1%;
            }
            color: #fff;
            .jiang_bei {
              display: flex;
              font-size: 18%;
              flex-grow: 1.2;
              font-weight: 600;
            }
            .nian_ji {
              font-size: 18%;
              font-weight: 500;
              flex-grow: 1;

            }
            .number {
              font-size: 18%;
              flex-grow: 1;
              font-weight: 500;
            }
            .xin_lv {
              font-size: 18%;
              flex-grow: 1;
               font-weight: 500;
            }

          }
        }
      }
      .personal_achievement {
        width: 100%;
        // height: 74%;
        height: 99%;
        margin-top: 2%;
        background: url("../assets/img/06.png") no-repeat;
        background-size: 100% 100%;
        padding: 3%;
        padding-left: 2%;
        padding-right: 2%;

        .tab_personal_top {
          display: flex;
          justify-content: space-between;
          padding-left: 1.5%;
          margin-top: 2%;
          li {
            // width: 11.11%;
            width: 8%;
            font-size: 16%;
            color: #fff;
          }
          .num{
            width: 13%;
          }
            .sem{
          width: 11%;
        }

        }
        .ul_box {
          // height: 91%;
          height: 94%;
          overflow-y: auto;
          .tab_personal_body {
            width: 100%;
            height: 7%;
            padding: 1.1%;
            padding-block: 2%;
            margin-top: 2%;
            background: url("../assets/img/11.png") no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            li {
              width: 8%;
              font-size: 18%;
              color: #fff;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-weight: 500;
            }
            .num{
            width: 15%;
          }
          .bh2{
            width: 8%;
          }
          .sem{
          width: 11%;
          }
          .bh{
            width: 6%;
          }
        li:nth-child(5) {
        padding-left: 1.5%;
        }
 }
 }
 .ul_box::-webkit-scrollbar {
display: none; /* Chrome Safari */
 }
}

    }
    .right_achievement {
      width: 33%;
      height: 100%;
      padding-top: 2%;
      padding-right: 3%;
      .achievement_box {
        // height: 32.52%;
        height: 99%;
        background: url("../assets/img/21.png") no-repeat;
        background-size: 100% 100%;
        padding: 2%;
        margin-bottom: 2%;
        .tab_personal_right {
          display: flex;
          justify-content: space-between;
          margin-top: 2%;
          li {
            // width: 10/%;
            // width: ;
            font-size: 16%;
            color: #fff;
          }
        }
        .tab_body {
          // height: 80%;
          height: 93%;
          padding-top: 2%;
          overflow-y: auto;
          scrollbar-width: none; /* firefox */
          -ms-overflow-style: none; /* IE 10+ */
          // display: flex;
          // align-items: center;
          // justify-content: space-between;
          li {
            width: 100%;
            // height: 20%;
            height: 26px;
            background: url("../assets/img/22.png") no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2%;
            color: #fff;
            font-size: 18%;
            padding-top: 0.2%;
            padding-bottom: 0.2%;
            img {
              width: 20%;
              margin-right: 1%;
            }
          }
        }
        .tab_body::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
      }
    }
    .center_box {
      width: 33%;
      height: 100%;
      padding-top: 2%;
      .number_box {
        display: flex;
        justify-content: space-between;
        height: 12%;
        li {
          width: 25%;
          position: relative;
          text-align: center;
          color: #fff;
          img {
            width: 50%;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%);
            margin-bottom: 14%;

          }
          .p1 {
            font-size: 30%;

            margin-top: -5%;

          }
          .p2 {
            font-size: 14%;
            margin-top: 40%;
            margin-left: -2%;
          }
        }
      }
      .average {
        height: 30%;
        margin-top: 1%;
        background: url("../assets/img/16.png") no-repeat;
        background-size: 100% 100%;
        .xinlv_box {
          height: 55%;
          padding-top: 6%;
          position: relative;
          img {
            width: 100%;
            position: absolute;
            bottom: 0;
          }
          .level_name {
            font-size: 16%;
            font-weight: bolder;
            color: #fff;
            position: absolute;
            bottom: 2%;
            left: 50%;
            transform: translate(-50%);
          }
          .xinlv_ul {
            height: 65%;
            display: flex;
            justify-content: space-between;
            padding-left: 6%;
            padding-right: 6%;
            .test{
            //  background-image: url(/img/18.2ecd0d5b.png);
             background-image: url('../assets/img/18.png');
              /* background-size: 100% 100%; */
              width: 16%;
              height:105%;
              // border: 1px solid;
              background-size: 100% 100%;
              margin-top: -3%;
            }
            li {
              width: 25%;
              position: relative;
              text-align: center;
              img {
                width: 60%;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%);
              }
              p {
                font-size: 16%;
                color: #fff;
                // font-weight: bolder;
                 font-weight: 600;
              }
              .p3{
                margin-top: 24%;
              }
              .p4{
                margin-top: 10%;
                font-size: 14%;
                 font-weight: 600;
              }
            }
          }
        }
        .class_xinlv_box {
          height: 40%;
          padding-top: 1%;
          padding-left: 1%;
          padding-right: 1%;
          display: flex;
          justify-content: space-between;
          font-weight: bolder;
          color: #fff;

          li {
            width: 24%;
            background: url("../assets/img/19.png") no-repeat;
            background-size: 100% 100%;
            padding-top: 2.5%;
            padding-left: 1%;
            padding-right: 1%;
            .class_name {
              font-size: 15%;
              text-align: center;
            }
            .p6{
                  margin-top: -2%;
                   font-size: 16%;
                }
                 .p5{
                  // margin-top: 21%;
                  margin-top: 30%;
                   font-size: 10px;
                };
                .p7{
                  margin-top: 37%;
                   font-size: 10px;
                }
            .lis {
              display: flex;
              justify-content: space-between;
              text-align: center;
              margin-top: 5%;
              .text_lis {
                font-size: 16%;
               margin-top:6%

                // width: 33.33%;
              }
            }
          }
        }
      }

       .xl_center_box{
        height: 18%;
        margin-top: 1%;
        background: url("../assets/img/16.png") no-repeat;
        background-size: 100% 100%;
        padding:2%;
        box-sizing: border-box;

        .xl_box_list{
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 48%;
          box-sizing: border-box;
          font-size: 18%;

          .xl_list_item{
            width: 49%;
            height: 100%;
            background-image: url('../assets/img/28.png');
            background-size: 100% 100%;
            // padding-top:2% ;
            box-sizing: border-box;
            color:#fff;
            // padding-left: 15%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:0 8%;
            box-sizing: border-box;
            img{
              width: 48px;
              height: 48px;
            }
            .title{
              font-size: 90%;
              margin-bottom: 1%;
            }
            .num{
              font-size: 120%;
              font-weight: 600;
            }
          }
        }
        .xl_box_list_tw{
          margin-top: 1.2%;
        }
      }
      .photo {
        height: 35%;
        margin-top: 2%;
        margin-bottom: 2%;
        position: relative;
        .bian_kuang {
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
        .play_box {
          width: 88%;
          height: 88%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        video {
          width: 89%;
          height: 89%;
          // object-fit: fill;
          object-fit: cover;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .xinlv_bottom_box {
        height: 18%;
        position: relative;
        padding-top: 2.5%;
        img {
          position: absolute;
          bottom: 2%;
        }
        .group_bottom_name {
          font-size: 16%;
          font-weight: bolder;
          color: #fff;
          position: absolute;
          bottom: 4%;
          left: 50%;
          transform: translate(-50%);
        }
        .xinlv_ul {
          height: 65%;
          display: flex;
          justify-content: space-between;
          padding-left: 6%;
          padding-right: 6%;
          .test{
             background-image: url('../assets/img/18.png');
            //  background-image: url(/img/18.2ecd0d5b.png);
              /* background-size: 100% 100%; */
              width: 16%;
              height: 88%;
              // border: 1px solid;
              background-size: 100% 100%;
              margin-top: 2%;
            }
          li {
            width: 25%;
            position: relative;
            text-align: center;
            img {
              width: 60%;
              position: absolute;
              left: 50%;
              transform: translate(-50%);
            }
            p {
              font-size: 16%;
              color: #fff;
              font-weight: bolder;
              font-weight: 600;
            }
            .p3{
                margin-top: 32%;
              }
              .p4{
                margin-top: 10%;
                font-size: 14%;
                 font-weight: 600;
              }

          }
        }
      }
    }
  }
}
/deep/.aaaaa{
  height:100%!important;
  margin-top: 0!important;
}
</style>
