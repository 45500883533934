import Vue from "vue";
import VueRouter from "vue-router";
import wyhyx from "../views/wyhyx.vue";
import LSWGY from "../views/lswgy.vue";
import BRZX from "../views/brzx.vue";
import wyhxdt from "../views/wyhxdt.vue";
import brzxxdt from "../views/brzxxdt.vue";
import cjsy from "../views/cjsy.vue";
import ywlxx from "../views/ywlxx.vue";
import wxxx from "../views/wxxx.vue";
import zhty from "../views/zhty.vue";
import bcxj from "../views/bcxj.vue";
import tzsyxx from "../views/tzsyxx.vue";
import ylyx from "../views/ylyx.vue";
import bcxx from "../views/bcxx.vue";
import ssty from "../views/ssty.vue";
import xmxx from "../views/xmxx.vue";
import wyh1 from "../views/wyh1.vue";
import smartSports from "../views/bigScreen/views/smartSports.vue";
import cjzgSports from "../views/bigScreen/views/cjzg.vue";
import hdzxSports from "../views/bigScreen/views/hdzx.vue";
import zgzxSports from "../views/bigScreen/views/zgzx.vue";
import lswgSports from "../views/bigScreen/views/lswg.vue";
// import wyhMai from "../views/bigScreen/views/wyhMai.vue";
// import wyhAi from "../views/bigScreen/views/wyhAi.vue";
import jumpScreen from "../views/bigScreen/views/jumpScreen.vue";
import trampolinejns from "../views/bigScreen/views/trampolinejns.vue";
import hdzx from "../views/hdzx.vue";
import cjzg from "../views/cjzg.vue";
import xjxqxq from "../views/xjxqxq.vue";
import xqxx from "../views/xqxx.vue";
import csgxqsx from "../views/csgxqsx.vue";
import sjc from "../views/sjc.vue";
import sqgjzx from "../views/sqgjzx.vue";
import bcys from "../views/bcys.vue";
import login from "../views/login/login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/bcys",
    name: "bcys",
    component: bcys,
  },
  {
    path: "/wyh1",
    name: "wyh1",
    component: wyh1,
  },
  {
    path: "/ssty",
    name: "ssty",
    component: ssty,
  },
  {
    path: "/cjzg",
    name: "cjzg",
    component: cjzg,
  },
  {
    path: "/xmxx",
    name: "xmxx",
    component: xmxx,
  },
  {
    path: "/tzsyxx",
    name: "tzsyxx",
    component: tzsyxx,
  },
  {
    path: "/ylyx",
    name: "ylyx",
    component: ylyx,
  },
  {
    path: "/bcxj",
    name: "bcxj",
    component: bcxj,
  },
  {
    path: "/bcxx",
    name: "bcxx",
    component: bcxx,
  },
  {
    path: "/",
    name: "wyh1",
    component: wyh1,
  },
  {
    path: "/wyhxdt",
    name: "wyhxdt",
    component: wyhxdt,
  },
  {
    path: "/lswgy",
    name: "LSWGY",
    component: LSWGY,
  },
  {
    path: "/brzx",
    name: "BRZX",
    component: BRZX,
  },
  {
    path: "/brzxxdt",
    name: "brzxxdt",
    component: brzxxdt,
  },
  {
    path: "/cjsy",
    name: "cjsy",
    component: cjsy,
  },
  {
    path: "/ywlxx",
    name: "ywlxx",
    component: ywlxx,
  },
  {
    path: "/wxxx",
    name: "wxxx",
    component: wxxx,
  },
  {
    path: "/zhty",
    name: "zhty",
    component: zhty,
  },
  {
    path: "/sjc",
    name: "sjc",
    component: sjc,
  },
  {
    path: "/sqgjzx",
    name: "sqgjzx",
    component: sqgjzx,
  },
  {
    path: "/smart-sports",
    name: "smartSports",
    component: smartSports,
  },
  {
    path: "/cjzg-sports",
    name: "cjzgSports",
    component: cjzgSports,
  },
  {
    path: "/hdzx-sports",
    name: "hdzxSports",
    component: hdzxSports,
  },
  {
    path: "/zgzx-sports",
    name: "zgzxSports",
    component: zgzxSports,
  },
  {
    path: "/lswg-sports",
    name: "lswgSports",
    component: lswgSports,
  },
  // {
  //   path:"/wyh-mai",
  //   name: 'wyhMai',
  //   component: wyhMai
  // },
  // {
  //   path:'/wyh-ai',
  //   name: 'wyhAi',
  //   component: wyhAi
  // },
  {
    path: "/jump-screen",
    name: "jumpScreen",
    component: jumpScreen,
  },
  {
    path: "/trampolinejns",
    name: "trampolinejns",
    component: trampolinejns,
  },

  {
    path: "/hdzx",
    name: "hdzx",
    component: hdzx,
  },
  {
    path: "/xjxqxq",
    name: "xjxqxq",
    component: xjxqxq,
  },
  {
    path: "/xqxx",
    name: "xqxx",
    component: xqxx,
  },
  {
    path: "/csgxqsx",
    name: "csgxqsx",
    component: csgxqsx,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // 在每次路由导航触发前执行的逻辑
  let token = localStorage.getItem("token");
  console.log(token, "token");
  if (token) {
    next();
  } else {
    if (to.path == "/login") {
      next();
    } else {
      sessionStorage.setItem("path", to.path);
      sessionStorage.setItem("query", JSON.stringify(to.query));
      next({ path: "/login" });
    }
  }
});

export default router;
