// 格式换日期
export function formatDate(date, type = 'dateTime', code = '-') {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    const timeObj = {
        date: `${year}${code}${addZero(month)}${code}${addZero(day)}`,
        monthDay: `${addZero(month)}${code}${addZero(day)}`,
        time: `${addZero(hour)}:${addZero(minute)}:${addZero(second)}`,
        hourMinute: `${addZero(hour)}:${addZero(minute)}`,
        dateTime: `${year}${code}${addZero(month)}${code}${addZero(day)} ${addZero(hour)}:${addZero(minute)}:${addZero(second)}`
    }

    return timeObj[type];
}

export function addZero(num) {
    return num < 10 ? "0" + num : num;
}

// 获取最近n天的日期
export function getRecentDays(nDays) {
    const currentTime = new Date().getTime();
    const oneDayTime = 24 * 60 * 60 * 1000;

    const dateList = [];
    for (let i = nDays - 1; i >= 0; i--) {
        const timestamp = currentTime - i * oneDayTime;
        const date = new Date(timestamp);
        dateList.push(formatDate(date));
    }

    return dateList;
}

// 获取当前的日期
export function getCurrentDate(type = 'dateTime', code = '-') {
    const date = new Date();
    return formatDate(date, type, code);
}

// 输入秒输出时分秒
export function outputCurrentTime(time, code=':', type = 'h:m:s'){
    let s = time < 0 ? 0 : Math.floor(time % 60);
    let m = time < 0 ? 0 : Math.floor((time / 60) % 60);
    let h = time < 0 ? 0 : Math.floor((time / 60 / 60) % 60);
    console.log(addZero(m),'addZero(m)==');
    console.log(addZero(s),'addZero(s)==');
    let timeObj = {
        'h:m:s': addZero(h) + code + addZero(m) + code + addZero(s),
        'm:s':  addZero(m) + code + addZero(s),
        "h'm''s'''":  addZero(h) + "'" + addZero(m) + "''" + addZero(s) + "'''",
        "m's''":  `${addZero(m)}'${addZero(s)}''`,
    }

    console.log(timeObj[type],'timeObj[type]===');

    return timeObj[type]
}
