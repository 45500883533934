export const Single_Ranking_Column = [
  {
    prop: "rank",
    align: "center",
    label: "排名",
    width: "15",
  },
  {
    prop: "student_name",
    align: "center",
    label: "姓名",
    width: "22",
  },
  // {
  //   prop: "gender",
  //   align: "center",
  //   label: "性别",
  //   width: "15",
  // },
  {
    prop: "school_name",
    align: "center",
    label: "学校",
    width: "80",
  },
  {
    prop: "height",
    align: "center",
    label: "高度",
    width: "16",
  },
];

export const Long_Ranking_Column = [
  {
    prop: "rank",
    align: "center",
    label: "排名",
    width: "20",
  },
  {
    prop: "simple_name",
    align: "center",
    label: "学校名称",
    width: "60",
  },
  {
    prop: "val_result",
    align: "center",
    label: "个数",
    width: "20",
  },
];
