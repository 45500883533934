<template>
  <div
    class="card relative pt-21 pl-10 pr-10 pb-10 overflow wb-100 hb-100"
    :class="[cardClass]">
    <div class="card_title center tw-bold fz-22 mb-30">{{ title }}</div>
    <div class="contain_box">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardClass: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: linear-gradient(151deg, #192556 0%, #1a317d 100%);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    background: url("../assets/images/jump-san.png") no-repeat;
    background-size: 100% 100%;
    width: 0.38rem;
    height: 0.38rem;
  }
}
.left {
  border-radius: 0rem 0.2rem 0rem 0.2rem;
  &::before {
    left: 0;
  }
}

.right {
  border-radius: 0.2rem 0rem 0.2rem 0rem;
  &::before {
    right: 0;
    transform: rotateY(180deg);
  }
}

.card_title {
  color: #79b0ff;
}

.contain_box {
  width: 100%;
  height: 85%;
}
</style>
