<template>
  <div class="center column pt-14">
    <div class="wb-100 br-20 hidden hb-50 total_bg pt-14 pl-42 pr-42 pb-14">
      <div class="t-blue-79 wb-100 cneter fz-26 tw-bold mb-20 center">
        吉尼斯挑战赛
      </div>
      <!-- <div class="align-center space-between pl-20 pr-20 wb-100">
        <div
          class="column align-center"
          v-for="(item, index) in totalDataList"
          :key="index">
          <number-animation
            :value="item.value"
            :animation_time="1"
            numType="num"
            class="fz-30 tw-bold t-blue-79 mb-4"></number-animation>

          <div class="t-w fz-22">{{ item.name }}</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import numberAnimation from "./numberAnimation.vue";
export default {
  components: {
    numberAnimation,
  },
  props: {
    totalData: {
      type: Object,
      default: {},
    },
  },
  watch: {
    totalData: {
      deep: true,
      immediate: true,
      handler(val) {
        this.totalData &&
          Object.keys(this.totalData).forEach((key) => {
            this.totalDataList.forEach((item) => {
              if (item.key == key) {
                item.value = this.totalData[key];
              }
            });
          });
      },
    },
  },
  data() {
    return {
      totalDataList: [
        {
          name: "参赛学校数",
          value: 0,
          key: "school_count",
        },
        {
          name: "参赛总人数",
          value: 0,
          key: "athlete_count",
        },
        {
          name: "单摇总人数",
          value: 0,
          key: "single_jump_count",
        },
        {
          name: "长绳队伍数",
          value: 0,
          key: "long_rope_jump_count",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.total_bg {
  background: url(../assets/images/jump-total-bg.png);
  background-size: 100% 100%;
}
</style>
